export const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'https://live.entromy.com';
export const PPT_URL = process.env.REACT_APP_PPT_URL ?? '';

export const API_URL = `${BASE_URL}/api`;
export const THEME_URL = `${process.env.REACT_APP_DEI_COMMON_URL ?? BASE_URL}/dei-common`;

export const LOCALES = [
  { locale: 'ms', language: 'Bahasa Melayu (Malay)' },
  { locale: 'my', language: 'မြန်မာဘာသာ (Burmese)' },
  { locale: 'zh-cn', language: '简体中文 (Chinese Simplified)' },
  { locale: 'zh-tw', language: '繁體中文 (Chinese Traditional)' },
  { locale: 'cs', language: 'Česky (Czech)' },
  { locale: 'da', language: 'Dansk (Danish)' },
  { locale: 'nl', language: 'Nederlands (Dutch)' },
  { locale: 'en', language: 'English' },
  { locale: 'fr', language: 'Français (French)' },
  { locale: 'fr-ca', language: 'Français (Canadian French)' },
  { locale: 'de', language: 'Deutsch (German)' },
  { locale: 'el', language: 'Ελληνικά (Greek)' },
  { locale: 'hmn', language: 'Hmong' },
  { locale: 'id', language: 'Bahasa Indonesia (Indonesian)' },
  { locale: 'it', language: 'Italiano (Italian)' },
  { locale: 'ja', language: '日本語 (Japanese)' },
  { locale: 'kar', language: 'Karen' },
  { locale: 'ko', language: '한국어 (Korean)' },
  { locale: 'pl', language: 'Polski (Polish)' },
  { locale: 'pt', language: 'Português (Portuguese Brazilian)' }, // Portuguese Brazil
  { locale: 'pt-pt', language: 'Português (Portuguese)' }, // Portuguese European
  { locale: 'ro', language: 'Română (Romanian)' },
  { locale: 'ru', language: 'Русский (Russian)' },
  { locale: 'sr-latn', language: 'Srpski (latinica) (Serbian Latin)' },
  { locale: 'es', language: 'Español (Spanish)' },
  { locale: 'es-x', language: 'Español - Latinoamerica (Spanish)' },
  { locale: 'sv', language: 'Svenska (Swedish)' },
  { locale: 'tl', language: 'Tagalog (Tagalog)' },
  { locale: 'th', language: 'ภาษาไทย (Thai)' },
  { locale: 'tr', language: 'Türkçe (Turkish)' },
  { locale: 'vi', language: 'Tiếng Việt (Vietnamese)' },
  { locale: 'hi', language: 'हिंदी (Hindi)' },
  { locale: 'am', language: 'አማርኛ (Amharic)' },
  { locale: 'ar', language: 'عربي (Arabic)' },
  { locale: 'eu', language: 'Euskara (Basque)' },
  { locale: 'bn', language: 'বাংলা (Bengali)' },
  { locale: 'bg', language: 'Български (Bulgarian)' },
  { locale: 'ca', language: 'Català (Catalan)' },
  { locale: 'hr', language: 'Hrvatski (Croatian)' },
  { locale: 'et', language: 'Eestlane (Estonian)' },
  { locale: 'fil', language: 'Pilipino (Filipino)' },
  { locale: 'fi', language: 'Suomalainen (Finnish)' },
  { locale: 'gu', language: 'ગુજરાતી (Gujarati)' },
  { locale: 'iw', language: 'עִברִית (Hebrew)' },
  { locale: 'hu', language: 'Magyar (Hungarian)' },
  { locale: 'is', language: 'Íslenska (Icelandic)' },
  { locale: 'kn', language: 'ಕನ್ನಡಕ (Kannada)' },
  { locale: 'lv', language: 'Latvietis (Latvian)' },
  { locale: 'lt', language: 'Lietuvis (Lithuanian)' },
  { locale: 'ml', language: 'മലയാളം (Malayalam)' },
  { locale: 'mr', language: 'मराठी (Marathi)' },
  { locale: 'no', language: 'Norsk (Norwegian)' },
  { locale: 'sk', language: 'Slovák (Slovak)' },
  { locale: 'sl', language: 'Slovenščina (Slovenian)' },
  { locale: 'sw', language: 'Kiswahili (Swahili)' },
  { locale: 'ta', language: 'தமிழ் (Tamil)' },
  { locale: 'te', language: 'తెలుగు (Telugu)' },
  { locale: 'ur', language: 'اردو (Urdu)' },
  { locale: 'uk', language: 'Українська (Ukrainian)' },
  { locale: 'cy', language: 'Cymraeg (Welsh)' },
  { locale: 'kk', language: 'Қазақ (Kazakh)' },
  { locale: 'pan', language: 'Punjabi (Devnagari)' },
  { locale: 'en-gb', language: 'English (United Kingdom)' },
  { locale: 'lo', language: 'ພາສາລາວ (Lao)' },
];
