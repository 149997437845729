import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Modal, Row, Table } from 'antd';
import PropTypes from 'prop-types';

import { showError } from '../../../utils/alerts';
import { post } from '../../../utils/backend';

import './styles.scss';

const SendAccessReportWithBackBtn = React.memo(
  ({ visible, onBack, onOk, selectedUsers, groups, setVisible }) => {
    const [loading, setLoading] = useState(false);
    const [goBack, setGoBack] = useState(false);
    function onCancel() {
      onBack();
      setGoBack(false);
    }

    async function onConfirm() {
      setLoading(true);
      try {
        await post('/users/bulk_send_credentials', selectedUsers);
        onOk();
      } catch (e) {
        showError(e.message);
      } finally {
        setLoading(false);
      }
    }

    function handleModalClose() {
      if (goBack) {
        onCancel();
      } else {
        onConfirm();
      }
    }

    const columns = [
      {
        title: 'Name',
        dataIndex: 'fullName',
        key: 'fullName',
        width: '20%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%',
      },
      {
        title: 'Credential Sent',
        dataIndex: 'credentialSent',
        key: 'credentialSent',
        width: '20%',
        render: (val, item) =>
          item.credentialSent ? 'Report access' : 'Report access & credentials',
      },
    ];

    const userCount = groups.filter((g) => selectedUsers.includes(g.userId))?.length ?? 0;
    const userText = userCount === '' ? 0 : userCount === 1 ? 'user' : 'users';

    return (
      <Modal
        className="send-access-report-with-back-btn"
        title="Review report access and credentials"
        open={visible}
        okButtonProps={{ loading }}
        width={800}
        closable={false}
        afterClose={() => handleModalClose()}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setGoBack(true);
              setVisible(false);
            }}
            className="back-button"
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => setVisible(false)}>
            Confirm & Send
          </Button>,
        ]}
      >
        {groups.some((g) => selectedUsers.includes(g.userId) && g.credentialSent) && (
          <Row className="info-modal">
            <Col span={24}>
              <Alert
                type="info"
                message="Users that already have credentials will receive an update with their new report access"
                closable
                showIcon
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={10}>
            <b>Access credentials will be emailed to:</b>
          </Col>
          <Col span={8} offset={6} className="align-right">
            <span>{`${userCount} ${userText}`}</span>
          </Col>
        </Row>
        <Table
          className="users-list"
          scroll={selectedUsers?.length > 5 ? { y: 200 } : {}}
          size="small"
          columns={columns}
          showHeader={false}
          dataSource={groups.filter((g) => selectedUsers.includes(g.userId))}
          pagination={false}
        />
      </Modal>
    );
  },
);

SendAccessReportWithBackBtn.defaultProps = {
  visible: false,
  onOk: () => {},
  onBack: () => {},
  setVisible: () => {},
};

SendAccessReportWithBackBtn.propTypes = {
  visible: PropTypes.bool,
  onBack: PropTypes.func,
  onOk: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.number).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      email: PropTypes.string,
      dimensionList: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
      fullReportAccess: PropTypes.bool,
      credentialSent: PropTypes.bool,
    }),
  ).isRequired,
  setVisible: PropTypes.func,
};

export default SendAccessReportWithBackBtn;
