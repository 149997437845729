/* eslint-disable max-len */
import XLSX from '@sheet/image';
import axios from 'axios';
import {
  chunk,
  compact,
  difference,
  find as _find,
  first,
  flattenDeep,
  groupBy,
  isNil,
  map,
  range,
  sortBy,
  sum as _sum,
  toArray,
  uniqBy,
} from 'lodash';
import moment from 'moment';

import {
  DEFAULT_UI_DATE_FORMAT,
  DIMENSION_VALUES_CODES,
  ONA_KEY_TERMS,
  QUESTIONS_METADATA,
  QUESTIONS_TYPES,
  QUESTIONS_TYPES_LIST,
  RATE_MAP,
  REPORT_VIEW_TYPE,
  STATEMENT_VISIBILITIES,
  SURVEY_TYPE,
} from '../constants';

import { post } from './backend';
import getHeatmapColor from './getHeatmapColor';
import { isSet, toInt, toNumber as toNumeric, toObject, toPercent, toString } from './helpers';

export function readFile(file, isExcelFile) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    if (isExcelFile) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file, 'UTF-8');
    }
  });
}

function normalizeColumnName(columnName) {
  return columnName
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, '')
    .replace(/ /g, '')
    .replace(/["']/g, '');
}

export function isCsvValid(csvText, requiredCols = [], optionalCols = []) {
  const colNames = csvText.split('\n')[0].split(',').map(normalizeColumnName);

  const requiredValid = requiredCols.every((colGroup) =>
    colGroup.some((colName) => colNames.includes(normalizeColumnName(colName))),
  );

  const optionalValid = optionalCols.every((col) => {
    const colIndex = col.colNumber - 1;
    if (colIndex < colNames.length && colNames[colIndex].trim() !== '') {
      return colNames[colIndex] === normalizeColumnName(col.name);
    }
    return true;
  });

  return requiredValid && optionalValid;
}

export function getValidSheets(fileData, requiredCols, optionalCols) {
  const wb = XLSX.read(fileData, { type: 'binary' });
  const validSheets = [];
  for (let i = 0; i < wb.SheetNames.length; i++) {
    const sheetName = wb.SheetNames[i];
    let sheet = wb.Sheets[sheetName];
    if (!sheet['!ref']) {
      continue;
    }
    const sheetRange = XLSX.utils.decode_range(sheet['!ref']) || {};
    const sheetRangeRowCount = sheetRange.e?.r || 0;
    const sheetRangeColCount = sheetRange.e?.c || 0;
    const cellAddresses = [];
    Object.keys(sheet).forEach((key) => {
      cellAddresses.push(XLSX.utils.decode_cell(key));
    });
    const rowCount = cellAddresses.map((item) => item.r).reduce((a, b) => Math.max(a, b)) + 1;
    if (sheetRangeRowCount - rowCount > 20) {
      sheet = XLSX.read(fileData, { type: 'binary', sheets: i, sheetRows: rowCount }).Sheets[
        sheetName
      ];
    }
    let csvText = XLSX.utils.sheet_to_csv(sheet, { blankrows: false, RS: '\n' });
    csvText = csvText.replace(/\r/g, '');
    const isValid = isCsvValid(csvText, requiredCols, optionalCols);
    const cols = [...Array(sheetRangeColCount + 1).keys()].map((index) => {
      const cellRef = XLSX.utils.encode_cell({ c: index, r: 0 });
      return {
        id: index,
        name: sheet[cellRef]?.w,
      };
    });
    cols.forEach((col) => {
      const examples = new Set();
      for (let r = 1; r < sheetRangeRowCount + 1; r++) {
        const cellRef = XLSX.utils.encode_cell({ c: col.id, r });
        if (toString(sheet[cellRef]?.w).trim().length) {
          examples.add(sheet[cellRef]?.w);
        }
        if (examples.size > 3) {
          break;
        }
      }
      if (examples.size) {
        col.examples = Array.from(examples).join(', ');
      }
    });
    if (isValid) {
      validSheets.push({
        csvText,
        name: wb.SheetNames[i],
        id: validSheets.length + 1,
        sheet,
        cols,
      });
    }
  }
  return validSheets;
}

export function validateONAKeyTerms(sheet, csvText) {
  const data = XLSX.utils.sheet_to_json(sheet);
  const colNames = csvText.split('\n')[0].split(',');
  const colNamesParsed = colNames.map((item) =>
    item
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, '')
      .replace(/ /g, '')
      .replace(/["']/g, ''),
  );
  const textColIndex = colNamesParsed.findIndex(
    (item) => item === 'question' || item === 'questiontext',
  );
  const typeColIndex = colNamesParsed.findIndex(
    (item) => item === 'type' || item === 'questiontype',
  );
  const keyTermColIndex = colNamesParsed.findIndex((item) => item === 'keyterms');

  if (keyTermColIndex === -1) {
    return;
  }
  const textColName = colNames[textColIndex];
  const typeColName = colNames[typeColIndex];
  const keyTermColName = colNames[keyTermColIndex];
  const { exportName } = QUESTIONS_TYPES_LIST.find((i) => i.id === QUESTIONS_TYPES.LIST);

  const wrongQuestions = data.filter(
    (item) =>
      toString(item[typeColName]).toLowerCase() === exportName.toLowerCase() &&
      ONA_KEY_TERMS.indexOf(item[keyTermColName]) === -1,
  );
  if (wrongQuestions.length) {
    return `Warning: the following list select question(s) ${wrongQuestions
      .map((item) => `"${item[textColName]}"`)
      .join(
        ',',
      )} do(es) not use standard Entromy key terms. In order to generate ONA reports correctly, the key terms for list select questions should be one of the following: Provides strategic direction, Helps with complex problems, Advice for decisions, Discuss new ideas, Provides support in difficulties, Inspires people, Personal support, Career advice, Unreachable enabler.`;
  }
}

async function getTemplate(url) {
  const { data } = await axios({
    url,
    method: 'GET',
    responseType: 'arraybuffer',
  });
  const fileData = new Uint8Array(data);
  return XLSX.read(fileData, { type: 'array', cellStyles: true, bookImages: true });
}

function formatSheetsNames(name, sheetNames) {
  const cleanStr = (str) =>
    str
      .replace(/\([0-9]\)/gi, '')
      .replace(/[^a-z0-9]/gi, ' ')
      .slice(0, 25);
  const dimension_name = cleanStr(name);
  const already_added = sheetNames.filter((element) => dimension_name === cleanStr(element));
  return already_added.length === 0
    ? dimension_name
    : `${dimension_name}(${already_added.length.toString()})`;
}

function setColWidth(sheet, colWidths) {
  if (!sheet['!cols']) {
    sheet['!cols'] = [];
  }
  for (let i = 0; i < colWidths.length; i++) {
    sheet['!cols'][i] = { wpx: colWidths[i] };
  }
}

// eslint-disable-next-line no-unused-vars
function setRowHeight(sheet, rowQty, height) {
  if (!sheet['!rows']) {
    sheet['!rows'] = [];
  }
  range(rowQty).forEach((_, i) => {
    sheet['!rows'][i] = { hpx: height };
  });
}

function styleCell(sheet, row, col, styles) {
  const cell = sheet[`${XLSX.utils.encode_col(col)}${row}`];
  if (!cell) {
    return;
  }
  cell.s = {
    bold: styles.bold,
    fgColor: styles.fgColor,
    color: styles.color,
    alignment: styles.alignment,
    sz: styles.sz,
  };
}

function colorCell(sheet, col, row, color = 0xa1c880) {
  const cell = sheet[`${XLSX.utils.encode_col(col)}${row}`];
  if (!cell) {
    return;
  }
  cell.s = {
    fgColor: { rgb: color },
  };
}

function formatCell(sheet, row, col, format) {
  const cell = sheet[`${XLSX.utils.encode_col(col)}${row}`];
  if (!cell) {
    return;
  }
  cell.z = format;
}

function colorRow(sheet, colCount, row, color = 0xa1c880) {
  XLSX.utils.sheet_set_range_style(sheet, `A${row}:${XLSX.utils.encode_col(colCount)}${row}`, {
    fgColor: { rgb: color },
  });
}

function boldRow(sheet, colCount, row) {
  XLSX.utils.sheet_set_range_style(sheet, `A${row}:${XLSX.utils.encode_col(colCount)}${row}`, {
    bold: true,
  });
}

function boldCol(sheet, rowCount, col) {
  XLSX.utils.sheet_set_range_style(
    sheet,
    `${XLSX.utils.encode_col(col)}${2}:${XLSX.utils.encode_col(col)}${rowCount + 1}`,
    {
      bold: true,
    },
  );
}

function alignColumn(sheet, rowCount, col, alignment) {
  XLSX.utils.sheet_set_range_style(
    sheet,
    `${XLSX.utils.encode_col(col)}${1}:${XLSX.utils.encode_col(col)}${rowCount}`,
    {
      alignment,
    },
  );
}

function wrapCenterRow(sheet, colCount, row) {
  XLSX.utils.sheet_set_range_style(sheet, `A${row}:${XLSX.utils.encode_col(colCount)}${row}`, {
    alignment: {
      wrapText: true,
      vertical: 'center',
    },
  });
}

function wrapRow(sheet, colCount, row) {
  XLSX.utils.sheet_set_range_style(sheet, `A${row}:${XLSX.utils.encode_col(colCount)}${row}`, {
    alignment: {
      wrapText: true,
      vertical: 'top',
    },
  });
}

function colorFirstRow(sheet, colCount) {
  colorRow(sheet, colCount, 1);
}

function freezeFirstRow(sheet) {
  sheet['!freeze'] = 'A2';
}

function saveToExcel(workbook, fileName) {
  XLSX.writeFile(workbook, `${fileName} - ${moment().format('YYYY-MM-DD HH-mm')}.xlsx`, {
    cellStyles: true,
    bookImages: true,
    compression: true,
  });
}

function saveToMemory(workbook) {
  return XLSX.write(workbook, {
    cellStyles: true,
    bookImages: true,
    compression: true,
    type: 'binary',
  });
}

export async function exportQuestionsFile(questions, fileName = 'Questions', localesFilter = []) {
  const templateWb = await getTemplate(`${window.location.origin}/xlsx/questions.xlsx`);

  const headers = ['Id', 'Question Text', 'Key Terms', 'Category', 'Question Type'];
  const data = [];
  const locales = {};
  const rowsToColor = [];
  questions.forEach((question) => {
    (question.translations || []).forEach((item) => {
      if (!locales[item.locale]) {
        locales[item.locale] = {};
      }
      if (!locales[item.locale].text) {
        let isLocalePresentInFilter = true;
        if (localesFilter && localesFilter.length > 0) {
          isLocalePresentInFilter = localesFilter.includes(item.locale);
        }
        locales[item.locale].text = isLocalePresentInFilter;
        if (item.stringval) {
          locales[item.locale].keyTerm = isLocalePresentInFilter;
        }
      }
    });
  });
  Object.keys(locales).forEach((locale) => {
    if (locales[locale].text) {
      headers.push(`Question Text:${locale}`);
    }
    if (locales[locale].keyTerm) {
      headers.push(`Key Terms:${locale}`);
    }
  });
  questions.forEach((question, index) => {
    const type = QUESTIONS_TYPES_LIST.find((item) => item.id === question.type);
    const getTranslations = () => {
      const res = [];
      Object.keys(locales).forEach((locale) => {
        const t = (question.translations || []).find((item) => item.locale === locale) || {};
        if (locales[locale].text) {
          res.push(t.textval);
        }
        if (locales[locale].keyTerm) {
          res.push(t.stringval);
        }
      });
      return res;
    };
    data.push([
      question.id,
      question.text,
      question.keyTerm,
      question.category,
      type.exportName,
      ...getTranslations(),
    ]);
    if (type.id === QUESTIONS_TYPES.SECTION) {
      rowsToColor.push(index + 2);
    }
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  rowsToColor.forEach((row) => {
    colorRow(ws, colCount, row);
  });
  setColWidth(ws, [100, 700, 250, 150, 100]);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Questions');

  const sheetName = templateWb.SheetNames[0];
  XLSX.utils.book_append_sheet(workbook, templateWb.Sheets[sheetName], sheetName);

  saveToExcel(workbook, fileName);
}
export async function exportUsersFile(users, dimensions, fileName = 'Users') {
  const templateWb = await getTemplate(`${window.location.origin}/xlsx/users.xlsx`);

  function getStatusName(value) {
    if (value === 1 || value === 5) return 'Active';
    if (value === 2) return 'Inactive';
    if (value === 3) return 'Deleted';
    return value;
  }
  const headers = [
    'FirstName',
    'LastName',
    'Email',
    'Status',
    'Role',
    'Kiosk Code',
    'Locale',
    'Contact Method',
    'Alternative Email',
  ];
  const data = [];
  dimensions.forEach((dimension) => headers.push(dimension.display_name));
  users.forEach((user) => {
    const row = [
      user.firstName,
      user.lastName,
      user.email,
      getStatusName(user.status),
      (user.role || {}).description,
      user.kioskCode,
      user.locale,
      user.contactMethod,
      user.alternativeEmail,
      ...dimensions.map(
        (dimension) =>
          (
            (user.userDimensions || []).find(
              (item) => item.dimension_code === dimension.dimension_code,
            ) || {}
          ).value,
      ),
    ];
    data.push(row);
  });
  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [150, 150, 250, 100, 100, 100, 100, 100]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Users');

  const sheetName = templateWb.SheetNames[0];
  XLSX.utils.book_append_sheet(workbook, templateWb.Sheets[sheetName], sheetName);

  saveToExcel(workbook, fileName);
}

export function exportWrongParticipantsData(headers, data, messageTypes = [], fileName = 'Errors') {
  const workbook = XLSX.utils.book_new();
  const resultData = [headers];
  data.forEach((d) => {
    const res = headers.map((h) => (d[h] === undefined ? '' : d[h]));
    resultData.push(res);
  });

  const ws = XLSX.utils.aoa_to_sheet(resultData);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;
  const rowCount = decodeRange?.e?.r || 1;

  setColWidth(
    ws,
    Array.from(Array(colCount).keys()).map(() => 150),
  );
  colorFirstRow(ws, colCount);
  boldRow(ws, colCount, 1);
  freezeFirstRow(ws);
  styleCell(ws, 1, 0, { fgColor: { rgb: 0xed7d31 }, bold: true });
  Array.from(Array(rowCount).keys()).forEach((item) => {
    const notificationType = messageTypes.find((mt) =>
      mt.message.indexOf(resultData[item + 1][0]),
    )?.type;
    if (notificationType !== undefined) {
      colorRow(ws, colCount, item + 2, notificationType === 'error' ? 0xf8cbad : 0xffd966);
    }
  });

  XLSX.utils.book_append_sheet(workbook, ws, 'Users');

  /* Instructions Sheet */

  const instructions = XLSX.utils.aoa_to_sheet([
    ['Entromy Error File Uploading Guidelines', ''],
    ['', ''],
    ['Instructions', ''],
    ['Types of Errors', 'Description'],
    [
      'Validation ',
      'These erros are marked in red. You will need to correct them to upload the user details',
    ],
    [
      'Warnings',
      'These are warnings that can result in potential issues. However, you will still be able to upload the user details',
    ],
    ['', ''],
    ['You can correct the errors in this file and reupload.'],
  ]);

  setColWidth(instructions, [600, 800]);
  const iRange = XLSX.utils.decode_range(instructions['!ref']);
  const iColCount = iRange?.e?.c || 1;
  colorFirstRow(instructions, iColCount);
  [1, 3, 4].forEach((r) => boldRow(instructions, iColCount, r));

  styleCell(instructions, 5, 0, { fgColor: { rgb: 0xf8cbad } });
  styleCell(instructions, 6, 0, { fgColor: { rgb: 0xffd966 } });

  XLSX.utils.book_append_sheet(workbook, instructions, 'Instructions');

  saveToExcel(workbook, fileName);
}

function setStyleForColumn(headers, columnName, data, ws, styles) {
  const colIndex = headers.indexOf(columnName);
  if (colIndex > -1) {
    for (let row = 0; row < data.length; row++) {
      styleCell(ws, row + 2, colIndex, styles);
    }
  }
}
export function exportStatements(
  statements,
  questions,
  dimensionFilterName,
  fileName,
  allowRanking,
) {
  const headers = [
    'Question Category',
    'Question Text',
    'Statement',
    allowRanking ? 'Total Score' : null,
    'Confidential',
    'Inappropriate',
    'Status',
  ].filter((n) => n);
  const data = [];
  const showLocales = statements.findIndex((item) => item.locale !== 'en') !== -1;
  if (dimensionFilterName !== 'None') {
    headers.push(`Originating ${dimensionFilterName}`);
  }
  if (showLocales) {
    headers.push('Locale', 'Locale Statement');
  }
  statements.forEach((item) => {
    const row = [
      questions[item.questionId]?.category,
      questions[item.questionId]?.text,
      item.textval,
      allowRanking ? item.score : null,
      item.confidential ? 'Yes' : 'No',
      item.flagged ? 'Yes' : 'No',
      toObject(STATEMENT_VISIBILITIES.find((i) => i.id === item.visibility_status)).name,
    ].filter((n) => n);
    if (dimensionFilterName !== 'None') {
      row.push(item.dimensionValue);
    }
    if (showLocales) {
      row.push(item.locale, item.locale_textval);
    }
    data.push(row);
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [150, 300, 600, 100, 100, 100, 100, 300]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Statements');

  saveToExcel(workbook, fileName);
}

export function exportAccessReportWithErrors(fileName, body) {
  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(body, {
    header: ['Email', 'Dimension Name', 'Dimension Value', 'Invalid'],
  });
  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [200, 200, 200, 300]);
  colorFirstRow(ws, colCount);
  styleCell(ws, 1, 3, { fgColor: { rgb: 0xef412f } });
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Report Access');

  saveToExcel(workbook, fileName);
}

export function exportViewersFile(viewers, dimensions, fileName) {
  const headers = ['Email', 'Dimension Name', 'Dimension Value'];
  const userRolesMapper = (user) => {
    if (user.fullReportAccess) {
      return [user.email, 'All Survey Results', ''];
    }
    const dimensionMap = {};
    dimensions.forEach((d) => {
      dimensionMap[d.dimension_code] = d.display_name;
    });
    return user.dimensionList.map((group) =>
      group.values.map((dimensionValue) => [user.email, dimensionMap[group.code], dimensionValue]),
    );
  };
  const data = chunk(flattenDeep(viewers.map((v) => userRolesMapper(v))), 3);
  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [200, 200, 200]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Viewers');

  saveToExcel(workbook, fileName);
}

export async function exportRespondentsFile(
  users,
  dimension1,
  dimension2,
  surveyTakingUrl,
  isSuperAdmin,
  dimensions = [],
  fileName = 'Users',
  surveyType,
) {
  function getStatusName(value) {
    if (value === 1 || value === 5) return 'Active';
    if (value === 2) return 'Inactive';
    if (value === 3) return 'Deleted';
    return value;
  }
  const isDei = surveyType === SURVEY_TYPE.DEI;
  const isComm = surveyType === SURVEY_TYPE.PARENT_360;
  const headers = ['Name', 'Email', 'Status'];
  if (!isDei) {
    headers.push(dimension1, dimension2);
  }
  headers.push('Date sent', 'Reminder date', 'Response date', 'Survey link');
  if (isComm) {
    headers.push('# of surveys assigned', '# of surveys completed', 'Participation rate');
  } else {
    headers.push('Participation');
    if (isSuperAdmin) {
      headers.push('Registered');
    }
  }
  const data = [];
  function formatDate(value) {
    return value ? moment(value).format(DEFAULT_UI_DATE_FORMAT) : '';
  }
  users.forEach((user) => {
    const row = [
      `${user.firstName || ''} ${user.lastName || ''}`,
      user.email,
      getStatusName(user.status),
    ];
    if (!isDei) {
      row.push(user.dimension1, user.dimension2);
    }
    row.push(
      formatDate(user.dateSent),
      formatDate(user.reminderDate),
      formatDate(user.responseDate),
      user.token ? surveyTakingUrl + user.token : '',
    );
    if (isComm) {
      row.push(
        user.assignedSurveysCount ?? 0,
        user.completedSurveysCount ?? 0,
        `${user.participationRate ?? 0}%`,
      );
    } else {
      row.push(user.responseDate ? 1 : 0);
      if (isSuperAdmin) {
        row.push(user.isRegistered ? 1 : 0);
      }
    }
    data.push(row);
  });
  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;
  setColWidth(
    ws,
    isComm
      ? [250, 250, 100, 200, 150, 150, 150, 150, 200, 200, 200, 200]
      : [250, 250, 100, 200, 150, 150, 100, 100, 100, 200, 100],
  );
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  setStyleForColumn(headers, 'Completion', data, ws, { alignment: { horizontal: 'right' } });

  XLSX.utils.book_append_sheet(workbook, ws, 'Users');

  if (toArray(dimensions).length) {
    const byDimensionData = [];
    dimensions
      .filter((item) => item.invited)
      .forEach((item) => {
        const dimension = byDimensionData.find((i) => i.id === item.dimensionId);
        const value = {
          value: item.dimensionValue,
          invited: item.invited,
          responded: item.responded,
        };
        if (dimension) {
          dimension.values.push(value);
        } else {
          byDimensionData.push({
            id: item.dimensionId,
            name: item.dimensionDisplayName,
            values: [value],
          });
        }
      });
    const filteredByDimensionData = byDimensionData.filter((i) => i.values.length > 1);
    filteredByDimensionData.forEach((dimension) => {
      const dimensionHeaders = ['Dimension value', 'Invites', 'Responses', 'Participation rate'];
      const dimensionData = [];
      dimension.values
        .sort((a, b) => b.invited - a.invited)
        .forEach((item) => {
          const row = [
            item.value,
            item.invited,
            item.responded,
            `${Math.round((item.responded * 100) / item.invited)}%`,
          ];
          dimensionData.push(row);
        });
      const sumReducer = (sum, value) => sum + value;
      const totalInvited = dimension.values.map((i) => i.invited).reduce(sumReducer, 0);
      const totalResponded = dimension.values.map((i) => i.responded).reduce(sumReducer, 0);
      dimensionData.push([
        'Total',
        totalInvited,
        totalResponded,
        `${Math.round((totalResponded * 100) / totalInvited)}%`,
      ]);
      const wsDimension = XLSX.utils.aoa_to_sheet([dimensionHeaders, ...dimensionData]);

      const rangeDim = XLSX.utils.decode_range(ws['!ref']);
      const colCountDim = rangeDim?.e?.c || 1;

      setColWidth(wsDimension, [250, 200, 200, 200]);
      colorFirstRow(wsDimension, colCountDim);
      freezeFirstRow(wsDimension);
      boldRow(wsDimension, colCountDim, dimension.values.length + 2);

      XLSX.utils.book_append_sheet(
        workbook,
        wsDimension,
        formatSheetsNames(dimension.name, workbook.SheetNames),
      );
    });
  }
  saveToExcel(workbook, fileName);
}

function calculateCategoryValues(data, isPercent = true) {
  const toNumber = (str) => parseInt(str.replace('%', ''), 10);
  const columnCount = 6;

  const calculateTotal = (dataByColumns, withPercent) => {
    if (dataByColumns.length === 0) return '';

    const total = dataByColumns.reduce((sum, current) => sum + current, 0);

    if (withPercent) {
      const totalByCategory = Math.round(total / data.length);
      return totalByCategory === 0 ? '' : `${totalByCategory}%`;
    }
    return total === 0 ? '' : total.toString();
  };

  return [...Array(columnCount).keys()].map((index) => {
    const dataByColumns = data.filter((d) => d[index]?.length > 0).map((d) => toNumber(d[index]));

    return calculateTotal(dataByColumns, isPercent);
  });
}

export async function exportReport(
  data,
  filters,
  fileName,
  max = 100,
  writeInMemory = false,
  params = {},
) {
  const columnCount = 6;
  const workbook = XLSX.utils.book_new();
  const { showDeltaInXlsx } = params;
  const { answerDistributionDisplayMode } = {
    answerDistributionDisplayMode: false,
    ...(data.rawData?.overview?.surveyType === '360' ? params[360] : params),
  };
  const allowRanking = filters?.allowRanking;

  function getDimensionValues(values, headers, isDelta = false) {
    const res = [];
    headers.forEach((item, index) => {
      if (isDelta) {
        if (index % 2) {
          item = item.replace('Delta', '').trim();
          res.push(values[item]?.delta);
        } else {
          res.push(values[item]?.score);
        }
      } else {
        res.push(values[item]?.score);
      }
    });
    return res;
  }

  function getDeltaColor(value) {
    return value > 0 ? '96c346' : 'a73437';
  }

  function getColorValues(values, row, bold = false, indexIndent = 1, withDelta = false) {
    const res = [];
    toArray(values).forEach((value, index) => {
      if (isSet(value)) {
        if (withDelta) {
          if (index % 2) {
            res.push({
              row,
              col: index + indexIndent,
              color: { rgb: getDeltaColor(value) },
              bold,
            });
          } else {
            res.push({
              row,
              col: index + indexIndent,
              fgColor: { rgb: getHeatmapColor(value, 0, max, undefined, true) },
              bold,
            });
          }
        } else {
          res.push({
            row,
            col: index + indexIndent,
            fgColor: { rgb: getHeatmapColor(value, 0, max, undefined, true) },
            bold,
          });
        }
      }
    });
    return res;
  }

  if (params.reportViewType === REPORT_VIEW_TYPE.Vcp) {
    exportVCP(data, workbook, answerDistributionDisplayMode);
  }

  // export overall scores
  if (
    toArray(data?.fixedAndNumerical?.questions).length &&
    toArray(data?.fixedAndNumerical?.categories).length
  ) {
    const headers = ['Type', 'Category/Question', 'Key terms', 'Score'].filter((n) => n);
    let showDelta = false;
    let showBenchmark = false;
    if (isSet(data?.overview?.delta)) {
      showDelta = true;
    }
    if (data.fixedAndNumerical.questions.filter((item) => item.minQ1).length) {
      showBenchmark = true;
    }
    if (showDelta) {
      headers.push('Delta');
    }
    if (showBenchmark) {
      headers.push(
        `Top Quartile Benchmark ${params?.benchmarkValues ? `(${params?.benchmarkValues})` : ''}`,
      );
      headers.push(
        `Average Benchmark ${params?.benchmarkValues ? `(${params?.benchmarkValues})` : ''}`,
      );
    }
    const rows = [];
    const boldRows = [];
    const cellsToColor = [];
    data.fixedAndNumerical.categories.forEach((category) => {
      boldRows.push(rows.length + 2);
      cellsToColor.push(...getColorValues([category.score], rows.length + 2, true, 3));
      if (category.delta) {
        cellsToColor.push({
          row: rows.length + 2,
          col: 4,
          color: { rgb: category.delta > 0 ? '96c346' : 'a73437' },
          bold: true,
        });
      }
      const row = ['Category', category.name, '', category.score].filter((n) => n !== null);

      if (showDelta) {
        row.push(category.delta);
      }
      if (showBenchmark) {
        row.push(category.minQ1 || '');
        row.push(category.minQ2 || '');
      }
      rows.push(row);
      const questions = data.fixedAndNumerical.questions.filter(
        (q) => q.categoryId === category.id,
      );
      questions.forEach((question) => {
        if (question.typeId === QUESTIONS_TYPES.FIXED) {
          cellsToColor.push(...getColorValues([question.score], rows.length + 2, false, 3));
        }
        if (question.delta && showDelta) {
          cellsToColor.push({
            row: rows.length + 2,
            col: 4,
            color: { rgb: question.delta > 0 ? '96c346' : 'a73437' },
          });
        }
        const qRow = ['Question', question.text, question.keyTerm, question.score].filter((n) => n);

        if (showDelta) {
          qRow.push(question.delta);
        }
        if (showBenchmark) {
          qRow.push(question.minQ1 || '');
          qRow.push(question.minQ2 || '');
        }
        rows.push(qRow);
      });
    });
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const decodeRange = XLSX.utils.decode_range(ws['!ref']);
    const colCount = decodeRange?.e?.c || 1;
    setColWidth(ws, [200, 600, 300, 300, 300, 300]);
    wrapRow(ws, colCount, 1);
    colorFirstRow(ws, colCount);
    freezeFirstRow(ws);
    boldRows.forEach((row) => {
      boldRow(ws, colCount, row);
    });
    cellsToColor.forEach((item) => {
      styleCell(ws, item.row, item.col, item);
    });

    ws['!autofilter'] = { ref: ws['!ref'] };
    XLSX.utils.book_append_sheet(workbook, ws, 'Overall scores');
  }

  //  export Answer distribution
  if (
    toArray(data?.fixedBreakdown?.categories).length &&
    toArray(data?.fixedBreakdown?.questions).length
  ) {
    const headers = [
      'Type',
      'Category/Question',
      'Key terms',
      'Strongly Disagree',
      'Disagree',
      'Somewhat Disagree',
      'Somewhat Agree',
      'Agree',
      'Strongly Agree',
    ];
    let rows = [];
    const boldRows = [];
    toArray(data?.fixedBreakdown?.categories).forEach((category) => {
      boldRows.push(rows.length + 2);
      const questions = data.fixedBreakdown.questions.filter((q) => q.categoryId === category.id);
      const qRows = [];
      const categoryCalculations = [];
      questions.forEach((question) => {
        const answers = [];
        [...Array(columnCount).keys()].forEach((val, index) => {
          const answer = question.answerOptions.filter((elem) => elem.id === index + 1);
          if (answerDistributionDisplayMode === 'percent') {
            answers[index] = answer.length > 0 ? `${answer[0].score}%` : '';
          } else {
            answers[index] = answer.length > 0 ? `${answer[0].count}` : '';
          }
        });
        const qRow = ['Question', question.text, question.keyTerm, ...answers.reverse()];
        categoryCalculations.push(answers);
        qRows.push(qRow);
      });
      if (
        questions.length &&
        questions.filter((elem) => elem.typeId === QUESTIONS_TYPES.FIXED).length > 0
      ) {
        const row = [
          'Category',
          category.name,
          '',
          ...calculateCategoryValues(
            categoryCalculations,
            answerDistributionDisplayMode === 'percent',
          ),
        ];
        rows.push(row);
        rows = rows.concat(qRows);
      }
    });

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const decodeRange = XLSX.utils.decode_range(ws['!ref']);
    const colCount = decodeRange?.e?.c || 1;
    setColWidth(ws, [200, 600, 250, 150, 150, 150, 150, 150, 150]);
    colorFirstRow(ws, colCount);
    freezeFirstRow(ws);
    boldRows.forEach((row) => {
      boldRow(ws, colCount, row);
    });
    ws['!autofilter'] = { ref: ws['!ref'] };
    XLSX.utils.book_append_sheet(workbook, ws, 'Answer distribution');
  }

  // export top comments
  if (toArray(data?.topStatements).length && data.rawData.overview.surveyType !== '360') {
    const headers = ['Question', 'Key term', 'Category', 'Statement'];
    const rows = [];
    if (filters.allowRanking) {
      headers.push('Score');
    }
    const addStatement = (category, question, statement) => {
      const row = [question.text, question.keyTerm, category.name, statement.text];
      if (filters.allowRanking) {
        row.push(statement.score);
      }
      rows.push(row);
    };
    data.topStatements.forEach((category) => {
      toArray(category.questions).forEach((question) => {
        if (question.enableTopics) {
          toArray(question.topics).forEach((item) => {
            toArray(item.statements).forEach((statement) => {
              addStatement(category, question, statement);
            });
          });
        } else {
          toArray(question.topics).forEach((statement) => {
            addStatement(category, question, statement);
          });
        }
      });
    });
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const decodeRange = XLSX.utils.decode_range(ws['!ref']);
    const colCount = decodeRange?.e?.c || 1;
    setColWidth(ws, [600, 300, 200, 600, 100]);
    colorFirstRow(ws, colCount);
    freezeFirstRow(ws);
    XLSX.utils.book_append_sheet(workbook, ws, allowRanking ? 'Top comments' : 'Comments');
  }

  // export multiselect data
  if (toArray(data?.multiselectData).length) {
    const headers = ['Question', 'Key term', 'Option', '# Chosen'];
    const rows = [];
    const transformedMultiselectData = [];
    toArray(data?.multiselectData).forEach((itm) => {
      toArray(itm.options).forEach((option) => {
        transformedMultiselectData.push({
          text: itm.questionText,
          keyTerm: itm.keyTerm,
          option: option.text,
          count: option.count,
        });
      });
    });
    toArray(transformedMultiselectData).forEach((item) => {
      rows.push([
        toString(item?.text),
        toString(item?.keyTerm),
        toString(item?.option),
        toInt(item?.count),
      ]);
    });
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const decodeRange = XLSX.utils.decode_range(ws['!ref']);
    const colCount = decodeRange?.e?.c || 1;
    setColWidth(ws, [600, 300, 200, 300, 100]);
    colorFirstRow(ws, colCount);
    freezeFirstRow(ws);
    XLSX.utils.book_append_sheet(workbook, ws, 'Ranked choice');
  }

  // export by dimension data
  if (toArray(data?.fixedByDimension).length) {
    data.fixedByDimension.forEach((dimension, index) => {
      // export fixed and numeric by dimension
      if (toArray(dimension.dimensions).length && toArray(dimension.questions).length) {
        const headers = ['Type', 'Category/Key Term'];
        const rows = [];
        const boldRows = [];
        const cellsToColor = [];
        dimension.dimensions.forEach((item) => {
          headers.push(item.name);
          if (showDeltaInXlsx) {
            headers.push(`${item.name} Delta`);
          }
        });
        boldRows.push(rows.length + 2);
        const respRow = ['Category', 'Number of respondents'];
        dimension.dimensions.forEach((item) => {
          respRow.push(item.respondents);
          if (showDeltaInXlsx) {
            respRow.push('');
          }
        });
        rows.push(respRow);
        boldRows.push(rows.length + 2);
        const overallRow = ['Category', 'Overall score'];
        dimension.dimensions.forEach((item) => {
          overallRow.push(item.score);
          if (showDeltaInXlsx) {
            overallRow.push('');
          }
        });
        rows.push(overallRow);
        if (dimension.id !== 'enps' && dimension.id !== 'topinfluencers') {
          boldRows.push(rows.length + 2);
          const participationRateRow = ['Category', 'Participation rate'];
          dimension.dimensions.forEach((item, colIndex) => {
            participationRateRow.push(
              item.participationRate ? `${item.participationRate}%` : item.participationRate,
            );
            cellsToColor.push({
              row: rows.length + 2,
              col: participationRateRow.length - 1,
              bold: true,
              alignment: { horizontal: 'right' },
            });
            if (showDeltaInXlsx) {
              participationRateRow.push(
                item.participationRateDelta
                  ? `${item.participationRateDelta}%`
                  : item.participationRateDelta,
              );
              cellsToColor.push({
                row: rows.length + 2,
                col: participationRateRow.length - 1,
                color: { rgb: getDeltaColor(item.participationRateDelta) },
                bold: true,
                alignment: { horizontal: 'right' },
              });
            }
          });
          rows.push(participationRateRow);
        }
        dimension.categories.forEach((category) => {
          boldRows.push(rows.length + 2);
          const values = getDimensionValues(
            category.dimensionValues,
            headers.slice(2),
            showDeltaInXlsx,
          );
          cellsToColor.push(...getColorValues(values, rows.length + 2, true, 2, showDeltaInXlsx));
          rows.push(['Category', category.name, ...values]);
          const questions = dimension.questions.filter((q) => q.categoryId === category.id);
          questions.forEach((question) => {
            const qValues = getDimensionValues(
              question.dimensionValues,
              headers.slice(2),
              showDeltaInXlsx,
            );
            if (question.typeId === QUESTIONS_TYPES.FIXED) {
              cellsToColor.push(
                ...getColorValues(qValues, rows.length + 2, false, 2, showDeltaInXlsx),
              );
            }
            rows.push(['Key term', question.keyTerm, ...qValues]);
          });
        });
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const decodeRange = XLSX.utils.decode_range(ws['!ref']);
        const colCount = decodeRange?.e?.c || 1;
        if (showDeltaInXlsx) {
          setColWidth(ws, [200, 400, headers.map(() => 150)].flat());
        } else {
          setColWidth(ws, [200, 400, headers.map(() => 150)].flat());
        }
        colorFirstRow(ws, colCount);
        freezeFirstRow(ws);
        boldRows.forEach((row) => {
          boldRow(ws, colCount, row);
        });
        cellsToColor.forEach((item) => {
          styleCell(ws, item.row, item.col, item);
        });
        ws['!autofilter'] = { ref: ws['!ref'] };
        XLSX.utils.book_append_sheet(
          workbook,
          ws,
          formatSheetsNames(dimension.name, workbook.SheetNames),
        );
      }
      // export nps
      const npsDimension = toArray(data?.npsBreakdownByDimension).find(
        (d) => d.code === dimension.id,
      );
      if (npsDimension) {
        const boldRows = [];
        const cellsToColor = [];
        const headers = ['Question', npsDimension.name, 'Score'];
        let showDelta = false;
        const rows = [];
        if (toArray(data?.npsBreakdown).find((q) => isSet(q.delta))) {
          headers.push('Delta');
          showDelta = true;
        }
        headers.push(
          'Responses',
          'Detractors',
          'Neutral',
          'Supporters',
          'Detractors %',
          'Neutral %',
          'Supporters %',
        );
        toArray(data?.npsBreakdown).forEach((question) => {
          let row = [question.text, 'Overall Scores', question.value];
          if (showDelta) {
            row.push(question.delta);
            if (isSet(question.delta)) {
              cellsToColor.push({
                row: rows.length + 2,
                col: 2,
                color: { rgb: question.delta > 0 ? '96c346' : 'a73437' },
                bold: true,
              });
            }
          }
          boldRows.push(rows.length + 2);
          row.push(
            question.responses,
            question.detractors,
            question.neutral,
            question.supporters,
            question.detractorsPerc,
            question.neutralPerc,
            question.supportersPerc,
          );
          rows.push(row);
          npsDimension.values
            .filter((item) => item.question_id === question.question_id)
            .forEach((item) => {
              row = [question.text, item.dimensionValue, item.value];
              if (showDelta) {
                row.push(item.delta);
                cellsToColor.push({
                  row: rows.length + 2,
                  col: 2,
                  color: { rgb: item.delta > 0 ? '96c346' : 'a73437' },
                  bold: true,
                });
              }
              row.push(
                item.responses,
                item.detractors,
                item.neutral,
                item.supporters,
                item.detractorsPerc,
                item.neutralPerc,
                item.supportersPerc,
              );
              rows.push(row);
            });
        });
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const decodeRange = XLSX.utils.decode_range(ws['!ref']);
        const colCount = decodeRange?.e?.c || 1;
        setColWidth(ws, [400]);
        colorFirstRow(ws, colCount);
        freezeFirstRow(ws);
        boldRows.forEach((r) => {
          boldRow(ws, colCount, r);
        });
        cellsToColor.forEach((item) => {
          styleCell(ws, item.row, item.col, item);
        });
        XLSX.utils.book_append_sheet(
          workbook,
          ws,
          formatSheetsNames(`NPS-${npsDimension.name}`, workbook.SheetNames),
        );
      }
      // export statements by dimension
      const statementCategories = toArray(data?.statementsByDimension).find(
        (dim) => dim.id === dimension.id,
      )?.categoryQuestions;
      if (
        toArray(statementCategories).length ||
        data.currentSpecial360?.length ||
        data.questionsStatementsByDimensions?.length ||
        data.customQuestionStatementsByDimensions?.length
      ) {
        const headers = ['Question', 'Key term', 'Category', 'Statement', dimension.name];
        if (filters.allowRanking) {
          headers.push('Score');
        }
        const rows = [];
        const addStatement = (dim, category, question, statement) => {
          const row = [
            question.questionText,
            question.keyterm,
            category.categoryTextval,
            statement.answerval,
            dim.name || 'Team feedback',
          ];
          if (filters.allowRanking) {
            row.push(Math.round(statement.percentage || 0));
          }
          rows.push(row);
        };
        statementCategories.forEach((category) => {
          toArray(category.employeeDimension).forEach((question) => {
            question.keyterm = data.rawData.questions[question.questionId]?.keyTerm;
            toArray(question.dimensionValues).forEach((item) => {
              item.topics.forEach((topic) => {
                if (toArray(topic.statements).length) {
                  topic.statements.forEach((statement) => {
                    addStatement(item, category, question, statement);
                  });
                } else {
                  addStatement(item, category, question, topic);
                }
              });
            });
          });
        });

        // Add multiselect answers
        const processQuestionsStatements = (statementsData) =>
          (statementsData?.questionsStatementsByDimensions ?? []).flatMap((questionsStatement) =>
            questionsStatement.flatMap((statement) =>
              statement.answers.flatMap((answer) =>
                answer.values.map((answerValue) => ({
                  dim: {
                    name: answer.sub_category.replace(/\(\d{1,}\)/, ''),
                  },
                  question: {
                    questionText: statement.title,
                    keyterm: statement.keyTerm,
                  },
                  category: {
                    categoryTextval: statement.leftSidebarData.prop,
                  },
                  statement: {
                    answerval: answerValue.text,
                    percentage: statement.leftSidebarData.percent,
                  },
                })),
              ),
            ),
          );
        const results = processQuestionsStatements(data);
        results.forEach((r) => addStatement(r.dim, r.category, r.question, r.statement));

        const processMultiselectData = (statementsData) =>
          (statementsData?.customQuestionStatementsByDimensions ?? []).flatMap(
            (questionsStatement) =>
              questionsStatement.answers.flatMap((answer) =>
                answer.values.map((answerValue) => ({
                  dim: {
                    name: answer.sub_category.replace(/\(\d{1,}\)/, ''),
                  },
                  question: {
                    questionText: questionsStatement.title,
                    keyterm: questionsStatement.keyTerm,
                  },
                  category: {
                    categoryTextval: questionsStatement.leftSidebarData.prop,
                  },
                  statement: {
                    answerval: answerValue.text,
                    percentage: questionsStatement.leftSidebarData.percent,
                  },
                })),
              ),
          );

        processMultiselectData(data).forEach((r) =>
          addStatement(r.dim, r.category, r.question, r.statement),
        );

        // Self-assessment
        const currentSpecial360 = data.currentSpecial360 ?? [];
        const selfAssessmentRows = currentSpecial360.map((question) => {
          const questionType = QUESTIONS_TYPES_LIST.find((type) => type.id === question.typeId);
          return [question.text, question.keyTerm, questionType.name, question.value, 'Self'];
        });

        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows, ...selfAssessmentRows]);
        const decodeRange = XLSX.utils.decode_range(ws['!ref']);
        const colCount = decodeRange?.e?.c || 1;
        setColWidth(ws, [600, 300, 200, 600, 200, 100]);
        colorFirstRow(ws, colCount);
        freezeFirstRow(ws);
        const getPrefix = () => {
          if (data.rawData.overview.surveyType !== '360') {
            if (allowRanking) {
              return 'Top comments ';
            }
            return 'Comments ';
          }
          return 'All comments by ';
        };
        XLSX.utils.book_append_sheet(
          workbook,
          ws,
          formatSheetsNames(`${getPrefix()}${dimension.name}`, workbook.SheetNames),
        );
      }

      // Answer distribution
      const { categories } = dimension;
      const rawQuestionsData = (data.fixedDistributionByDimension ?? []).find(
        (i) => i.id === dimension.id,
      )?.questions;
      if (
        toArray(categories).length &&
        toArray(rawQuestionsData).length &&
        data.rawData.overview.surveyType !== '360'
      ) {
        const formatQuestions = (questionsToBeFormatted) =>
          compact(
            toArray(questionsToBeFormatted).map((item) => {
              const question = toArray(data.rawData?.fixedAndNumerical?.questions).filter(
                (q) =>
                  data.rawData.questions[item.id]?.keyTerm === q.keyTerm &&
                  data.rawData.questions[item.id]?.categoryId === q.categoryId,
              );
              if (question.length) {
                return { ...first(question), ...item };
              }
              return false;
            }),
          );

        const headers = [
          'Type',
          'Dimension value',
          'Category/Question',
          'Key terms',
          'Strongly Disagree',
          'Disagree',
          'Somewhat Disagree',
          'Somewhat Agree',
          'Agree',
          'Strongly Agree',
        ];
        const rows = [];
        const boldRows = [];
        const { dimensions } = dimension;
        const questions = formatQuestions(rawQuestionsData);
        if (dimensions.length) {
          dimensions.forEach((dl) => {
            toArray(categories).forEach((category) => {
              boldRows.push(rows.length + 2);
              const qRows = [];
              const categoryCalculations = [];
              questions
                .filter((q) => q.categoryId === category.id && q.dimensionValue === dl.name)
                .forEach((question) => {
                  const answers = [];
                  [...Array(columnCount).keys()].forEach((val, colIndex) => {
                    const answer = question.answerOptions.filter(
                      (elem) => elem.id === colIndex + 1,
                    );
                    if (answerDistributionDisplayMode === 'percent') {
                      answers[colIndex] = answer.length > 0 ? `${answer[0].score}%` : '';
                    } else {
                      answers[colIndex] = answer.length > 0 ? `${answer[0].count}` : '';
                    }
                  });
                  const qRow = [
                    'Question',
                    question.dimensionValue,
                    question.text,
                    question.keyTerm,
                    ...answers.reverse(),
                  ];
                  categoryCalculations.push(answers);
                  qRows.push(qRow);
                });
              if (
                qRows.length &&
                questions.filter((elem) => elem.typeId === QUESTIONS_TYPES.FIXED).length > 0
              ) {
                const row = [
                  'Category',
                  dl.name,
                  category.name,
                  '',
                  ...calculateCategoryValues(
                    categoryCalculations,
                    answerDistributionDisplayMode === 'percent',
                  ),
                ];
                rows.push(row, ...qRows);
              }
            });
            rows.push([]);
          });
        }

        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const rangeDim = XLSX.utils.decode_range(ws['!ref']);
        const colCount = rangeDim?.e?.c || 1;
        setColWidth(ws, [200, 150, 600, 250, 150, 150, 150, 150, 150, 150]);
        colorFirstRow(ws, colCount);
        freezeFirstRow(ws);
        boldRows.forEach((row) => {
          boldRow(ws, colCount, row);
        });
        ws['!autofilter'] = { ref: ws['!ref'] };
        XLSX.utils.book_append_sheet(
          workbook,
          ws,
          formatSheetsNames(`Answer distribution-${dimension.name}`, workbook.SheetNames),
        );
      }
    });
  }
  const { categories, questions } = data?.fixedBreakdownByDimmension;
  if (toArray(categories).length && toArray(questions).length) {
    const headers = [
      'Type',
      'Dimension value',
      'Category/Question',
      'Key terms',
      'Strongly Disagree',
      'Disagree',
      'Somewhat Disagree',
      'Somewhat Agree',
      'Agree',
      'Strongly Agree',
    ];
    const rows = [];
    const boldRows = [];
    const dimensionList = toArray(data?.fixedByDimension[0]?.dimensions);
    if (dimensionList.length) {
      dimensionList.forEach((dl) => {
        toArray(categories).forEach((category) => {
          boldRows.push(rows.length + 2);
          const qRows = [];
          const categoryCalculations = [];
          questions
            .filter((q) => q.categoryId === category.id && q.dimensionValue === dl.name)
            .forEach((question) => {
              const answers = [];
              [...Array(columnCount).keys()].forEach((val, index) => {
                const answer = question.answerOptions.filter((elem) => elem.id === index + 1);
                if (answerDistributionDisplayMode === 'percent') {
                  answers[index] = answer.length > 0 ? `${answer[0].score}%` : '';
                } else {
                  answers[index] = answer.length > 0 ? `${answer[0].count}` : '';
                }
              });
              const qRow = [
                'Question',
                question.dimensionValue,
                question.text,
                question.keyTerm,
                ...answers.reverse(),
              ];
              categoryCalculations.push(answers);
              qRows.push(qRow);
            });
          if (
            questions.length &&
            questions.filter((elem) => elem.typeId === QUESTIONS_TYPES.FIXED).length > 0
          ) {
            const row = [
              'Category',
              dl.name,
              category.name,
              '',
              ...calculateCategoryValues(
                categoryCalculations,
                answerDistributionDisplayMode === 'percent',
              ),
            ];
            rows.push(row, ...qRows);
          }
        });
        rows.push([]);
      });
    }

    // For 360 survey there is only 1 dimension
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const decodeRange = XLSX.utils.decode_range(ws['!ref']);
    const colCount = decodeRange?.e?.c || 1;
    setColWidth(ws, [200, 150, 600, 250, 150, 150, 150, 150, 150, 150]);
    colorFirstRow(ws, colCount);
    freezeFirstRow(ws);
    boldRows.forEach((row) => {
      boldRow(ws, colCount, row);
    });
    ws['!autofilter'] = { ref: ws['!ref'] };
    XLSX.utils.book_append_sheet(
      workbook,
      ws,
      formatSheetsNames(
        `Answer distribution-${data?.fixedByDimension[0]?.name}`,
        workbook.SheetNames,
      ),
    );
  }

  const showManagerReview = (question) => {
    if (question.typeId !== QUESTIONS_TYPES.SPECIAL_360) return false;
    const type = toArray(question.metadata).find(
      (i) => i.dimensionCode === DIMENSION_VALUES_CODES.TYPE_360,
    )?.value;
    const isOverallRating =
      type === QUESTIONS_METADATA.SELF_ASSESSMENT_RATE &&
      data.slideSettings?.[360]?.showManagerOverallRating;
    const isManagerFeedback =
      type === QUESTIONS_METADATA.SELF_ASSESSMENT_TEXT &&
      data.slideSettings?.[360]?.showManagerFeedback;
    return isOverallRating || isManagerFeedback;
  };

  // export Manager review sheet
  const addManagerReviewSheet = (managers) => {
    const headers = ['Question', 'Key term', 'Category', 'Statement', 'Manager name'];
    const colSizes = [200, 200, 200, 1000, 200];
    const colStatement = 4;
    const rawCategories = data.rawData?.categories ?? {};
    const rows = managers.flatMap((manager) => {
      return manager.questions.filter(showManagerReview).map((question) => {
        const questionValue =
          question.typeId === QUESTIONS_TYPES.SPECIAL_360 && RATE_MAP[question.value]
            ? `${question.value} - ${RATE_MAP[question.value]}`
            : question.value;
        return [
          question.text,
          question.keyTerm,
          rawCategories[question.categoryId],
          questionValue,
          manager.name,
        ];
      });
    });

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    setColWidth(ws, colSizes);
    colorFirstRow(ws, headers.length - 1);
    range(1, rows.length + 2).forEach((row) => wrapRow(ws, colStatement, row));
    XLSX.utils.book_append_sheet(workbook, ws, 'Manager review');
  };

  if (
    data.currentSpecial360Managers?.some((manager) => manager.questions?.some(showManagerReview))
  ) {
    addManagerReviewSheet(data.currentSpecial360Managers);
  }

  const isDimensionColumnHasData = (key, dataList) => {
    return dataList.filter((item) => item[key] !== 'Uncategorized').length > 0;
  };

  // export top influencers
  const addTopInfluencersSheet = (topInfluencersData) => {
    const nominationColumns = topInfluencersData?.data.length
      ? topInfluencersData.data[0].scores
      : [];
    const rowsData = topInfluencersData?.data.map((item, index) => {
      const row = { ...item, index: index + 1 };
      item.scores.forEach((score) => {
        row[score.category] = score.nominationsCount;
      });
      return row;
    });
    const headers = [
      {
        head: '#',
        key: 'index',
        width: 32,
        excluded: false,
      },
      {
        head: 'Name',
        key: 'name',
        width: 200,
        excluded: false,
      },
      ...Object.keys(topInfluencersData?.dimensionFieldToName).map((key) => ({
        head: topInfluencersData.dimensionFieldToName[key],
        key,
        width: 200,
        excluded: !isDimensionColumnHasData(key, rowsData),
      })),
      ...nominationColumns.map((item) => ({
        head: item.category,
        key: item.category,
        width: 200,
        excluded: false,
      })),
      {
        head: 'Total',
        key: 'totalNominationsCount',
        width: 80,
        excluded: false,
      },
    ].filter((item) => !item.excluded);
    const colSizes = headers.map((item) => item.width);
    const rows = rowsData.map((item) => headers.map((headerItem) => item[headerItem.key]));
    const ws = XLSX.utils.aoa_to_sheet([headers.map((item) => item.head), ...rows]);
    setColWidth(ws, colSizes);
    colorFirstRow(ws, headers.length - 1);
    XLSX.utils.book_append_sheet(workbook, ws, 'Potential influencers');
  };

  const addAdditionalInfluencersSheet = (dimensionFieldToName, rowsData, sheetName) => {
    const dimensionList = ['dimension1', 'dimension2', 'dimension3'].filter((key) =>
      isDimensionColumnHasData(key, rowsData),
    );
    const headers = [
      '#',
      'Name',
      ...dimensionList.map((key) => dimensionFieldToName[key]),
      'Total',
    ];
    const colSizes = [80, 200, 200, 200, 200, 150];
    const rows = rowsData.map((item, index) => [
      index + 1,
      item.name,
      ...dimensionList.map((key) => item[key]),
      item.totalNominationsCount,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    setColWidth(ws, colSizes);
    colorFirstRow(ws, headers.length - 1);
    XLSX.utils.book_append_sheet(workbook, ws, sheetName);
  };

  if (data.onaPotentialInfluencersIdentified?.data?.length > 0) {
    addTopInfluencersSheet(data.onaPotentialInfluencersIdentified);
    // export recommended Influencers
    const recommendedInfluencersData = data.onaPotentialInfluencersIdentified?.data.filter(
      (item) => item.recommended,
    );
    if (recommendedInfluencersData.length) {
      addAdditionalInfluencersSheet(
        data.onaPotentialInfluencersIdentified?.dimensionFieldToName,
        recommendedInfluencersData,
        'Recommended influencers',
      );
    }
    // export bottleneck
    const bottleneckData = data.onaPotentialInfluencersIdentified?.data.filter(
      (item) => item.bottleneck,
    );
    if (bottleneckData.length) {
      addAdditionalInfluencersSheet(
        data.onaPotentialInfluencersIdentified?.dimensionFieldToName,
        bottleneckData,
        'Bottlenecks',
      );
    }
  }

  if (writeInMemory) {
    // END
    return saveToMemory(workbook);
  }
  saveToExcel(workbook, fileName);
}

function getPortfolioOverviewSheet(data, params) {
  const { showDeltas, compareEnabled, showBenchmarks, exportedColumnKeys } = params;
  const comparedColHeader = showDeltas ? 'Delta' : 'Prior';
  const isExportedColumn = (columnKey) => exportedColumnKeys.includes(columnKey);

  function getColumnValues(row) {
    const res = [];
    for (let i = 0; i < data.columns.length; i++) {
      const col = data.columns[i];
      if (!isExportedColumn(col.id)) continue;
      if (row.typeId === QUESTIONS_TYPES.STATEMENT) {
        res.push(
          toArray(row[col.id]?.current)
            .map((s) => (s.score ? `${s.text}(${s.score})` : s.text))
            .slice(0, 20)
            .join('; ')
            .substring(0, 5000),
        );
        continue;
      }
      if (row.typeId === QUESTIONS_TYPES.LIST) {
        res.push(
          toArray(row[col.id]?.current)
            .map((s) => `${s.value} / ${s.title} (${s.score})`)
            .slice(0, 20)
            .join('; ')
            .substring(0, 5000),
        );
        continue;
      }

      res.push(...getValuesForParticipantColumn(row, col.id));
    }
    return res;
  }

  function getColorValues(values, row, bold = false, indexIndent = 1) {
    const res = [];
    values.forEach((value, index) => {
      if (isSet(value)) {
        res.push({
          row,
          col: index + indexIndent,
          fgColor: { rgb: getHeatmapColor(value, 0, params.heatMapMax || 100, undefined, true) },
          bold,
        });
      }
    });
    return res;
  }

  function getDeltaColorValues(values, row, bold = false, indexIndent = 1) {
    const res = [];
    values.forEach((value, index) => {
      if (isSet(value)) {
        res.push({
          row,
          col: index + indexIndent,
          bold,
          color: { rgb: value > 0 ? '96c346' : 'a73437' },
        });
      }
    });
    return res;
  }

  function isComparedAvailableForColumn(columnCode) {
    return (
      compareEnabled &&
      data.rows.some(
        (row) =>
          !isNil(row[columnCode]?.compared) ||
          row.children?.some((child) => !isNil(child[columnCode]?.compared)),
      )
    );
  }

  function getHeadersForColumnCode(code, mainHeaderName, withCompared) {
    if (!isExportedColumn(code)) return [];
    return getHeadersForColumn(mainHeaderName, withCompared);
  }

  function getHeadersForColumn(mainHeaderName, withCompared) {
    return withCompared ? [mainHeaderName, comparedColHeader] : [mainHeaderName];
  }

  function getValuesForStaticColumn(valueObj, key, withCompared) {
    if (!isExportedColumn(key)) return [];
    return withCompared
      ? [valueObj?.[key]?.current, getComparedValueByKey(valueObj, key)]
      : [valueObj?.[key]?.current];
  }

  function getValuesForParticipantColumn(row, colId) {
    const currentValue = getCurrentColumnValueFromRow(row, colId);
    return isComparedAvailableForColumn(colId)
      ? [currentValue, getComparedValue(row[colId])]
      : [currentValue];
  }

  function getComparedValueByKey(valueObj, key) {
    return getComparedValue(valueObj?.[key]);
  }

  function getComparedValue(valueObj) {
    return showDeltas
      ? valueObj?.compared
      : isNil(valueObj?.compared) || isNil(valueObj?.current)
      ? undefined
      : valueObj?.current - valueObj?.compared;
  }

  function getCurrentColumnValueFromRow(row, colId) {
    return !compareEnabled && showDeltas && !row.noDelta
      ? !isNil(row[colId]?.current)
        ? row[colId]?.current - row.average?.current
        : undefined
      : row[colId]?.current;
  }

  function getCellsToColor(
    row,
    rowIdx,
    columnValues,
    deltaColumns,
    isBold,
    benchmarksWithComparedDelta,
    averageWithComparedDelta,
    spreadWithComparedDelta,
  ) {
    const result = [];

    if (!row.noColor) {
      const staticColumnFields = [
        showBenchmarks && isExportedColumn('benchmark')
          ? { field: row.benchmark, hasDeltaCol: benchmarksWithComparedDelta }
          : null,
        isExportedColumn('average')
          ? { field: row.average, hasDeltaCol: averageWithComparedDelta }
          : null,
        isExportedColumn('spread')
          ? { field: row.spread, hasDeltaCol: spreadWithComparedDelta, skipCurrent: true }
          : null,
      ].filter(Boolean);
      if (showDeltas) {
        let curColIdx = 1;
        staticColumnFields.forEach((item) => {
          if (item.skipCurrent) {
            curColIdx += item.hasDeltaCol ? 2 : 1;
            return;
          }
          result.push(...getColorValues([item.field?.current], rowIdx, isBold, curColIdx++));
          if (item.hasDeltaCol) {
            result.push(
              ...getDeltaColorValues([item.field?.compared], rowIdx, isBold, curColIdx++),
            );
          }
        });
        if (!compareEnabled) {
          result.push(...getDeltaColorValues(columnValues, rowIdx, isBold, curColIdx));
        } else {
          columnValues.forEach((val, idx) => {
            if (deltaColumns[idx]) {
              result.push(...getDeltaColorValues([val], rowIdx, isBold, curColIdx++));
            } else {
              result.push(...getColorValues([val], rowIdx, isBold, curColIdx++));
            }
          });
        }
      } else {
        const staticColumns = (
          isExportedColumn('spread')
            ? staticColumnFields.slice(0, -1) // eliminate last (spread) column from colororization process
            : staticColumnFields
        ).reduce(
          (res, item) =>
            item.hasDeltaCol
              ? res.concat([item.field?.current, getComparedValue(item.field)])
              : res.concat([item.field?.current]),
          [],
        );
        result.push(...getColorValues(staticColumns, rowIdx, isBold));
        result.push(
          ...getColorValues(
            columnValues,
            rowIdx,
            isBold,
            staticColumns.length +
              (isExportedColumn('spread') ? (spreadWithComparedDelta ? 3 : 2) : 1),
          ),
        );
      }
    }

    return result;
  }

  const benchmarksWithComparedDelta = isComparedAvailableForColumn('benchmark');
  const averageWithComparedDelta = isComparedAvailableForColumn('average');
  const spreadWithComparedDelta = isComparedAvailableForColumn('spread');
  const staticColumnHeaders = [
    '',
    ...(showBenchmarks
      ? getHeadersForColumnCode('benchmark', 'Benchmarks', benchmarksWithComparedDelta)
      : []),
    ...getHeadersForColumnCode('average', 'Average', averageWithComparedDelta),
    ...getHeadersForColumnCode('spread', 'Spread', spreadWithComparedDelta),
  ];
  const indexIndent = staticColumnHeaders.length;
  const rows = [];
  const boldRows = [];
  const cellsToColor = [];
  const wrapRows = [];
  const headers = data.columns.reduce(
    (res, item) =>
      res.concat(...getHeadersForColumn(item.fullNameRaw, isComparedAvailableForColumn(item.id))),
    staticColumnHeaders,
  );
  const deltaColumns = headers
    .slice(staticColumnHeaders.length)
    .map((header) => header.includes(comparedColHeader));
  data.rows.forEach((category) => {
    if (category.isBold) {
      boldRows.push(rows.length + 2);
    }
    const values = [
      ...(showBenchmarks
        ? getValuesForStaticColumn(category, 'benchmark', benchmarksWithComparedDelta)
        : []),
      ...getValuesForStaticColumn(category, 'average', averageWithComparedDelta),
      ...getValuesForStaticColumn(category, 'spread', spreadWithComparedDelta),
      ...getColumnValues(category),
    ];

    cellsToColor.push(
      ...getCellsToColor(
        category,
        rows.length + 2,
        values.slice(indexIndent - 1),
        deltaColumns,
        true,
        benchmarksWithComparedDelta,
        averageWithComparedDelta,
        spreadWithComparedDelta,
      ),
    );
    rows.push([category.name, ...values]);

    toArray(category.children).forEach((question) => {
      const qValues = [
        ...(showBenchmarks
          ? getValuesForStaticColumn(question, 'benchmark', benchmarksWithComparedDelta)
          : []),
        ...getValuesForStaticColumn(question, 'average', averageWithComparedDelta),
        ...getValuesForStaticColumn(question, 'spread', spreadWithComparedDelta),
        ...getColumnValues(question),
      ];
      cellsToColor.push(
        ...getCellsToColor(
          question,
          rows.length + 2,
          qValues.slice(indexIndent - 1),
          deltaColumns,
          false,
          benchmarksWithComparedDelta,
          averageWithComparedDelta,
          spreadWithComparedDelta,
        ),
      );
      if (question.wrapText) {
        wrapRows.push(rows.length + 2);
      }
      rows.push([question.name, ...qValues]);
    });
  });
  return {
    sheetData: [headers, ...rows],
    boldRows,
    cellsToColor,
    wrapRows,
  };
}

export function exportPortfolio(data, fileName, params) {
  const workbook = XLSX.utils.book_new();
  const result = getPortfolioOverviewSheet(data, params);
  const ws = XLSX.utils.aoa_to_sheet(result.sheetData);
  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;
  setColWidth(ws, [400, 80, 80, 80, ...data.columns.map(() => 150)]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);
  result.boldRows.forEach((row) => {
    boldRow(ws, colCount, row);
  });
  result.cellsToColor.forEach((item) => {
    styleCell(ws, item.row, item.col, item);
  });
  XLSX.utils.book_append_sheet(workbook, ws, 'Portfolio view');
  saveToExcel(workbook, fileName);
}

const getVCPValueDriversSheet = (data) => {
  const VCPValueDriversHeaders = [
    'Type',
    'Value driver name',
    'Key roles',
    'Department',
    'Value at stake ($M)',
    'Weight on VCP/Value driver',
    'OHB score',
    'Confidence to deliver',
  ];

  const rolesByDriver = groupBy(data?.confidenceLevels?.plainDriverRoles, 'valueDriverId');
  const VCPValueDriversData = [];
  const tmpKeyRoles = [];
  const tmpValueDrivers = [];
  const driversStakeValues = [];
  const driversPercents = [];
  Object.keys(rolesByDriver).forEach((driverID) => {
    const driver = data?.confidenceLevels.drivers.find(
      (driverItem) => driverItem.id === parseInt(driverID),
    );
    const category = data?.fixedAndNumericalByDriver?.categories.find(
      (categoryItem) => categoryItem.name === driver.keyTerm,
    );
    tmpValueDrivers.push([
      'Value driver',
      driver?.keyTerm,
      '',
      '',
      `${driver?.stakeValue ?? ''}`,
      `${driver?.percent ?? ''}%`,
      `${category?.score ?? ''}`,
      `${driver?.confidenceLevel ?? ''}`,
    ]);

    tmpKeyRoles.push(
      ...rolesByDriver[driverID]
        .map((role) => [
          'Key role',
          driver.keyTerm,
          role.role,
          role.department,
          `${role?.stakeValue}`,
          `${role?.percent}%`,
          '',
          '',
        ])
        .sort((a, b) => b[4] - a[4]),
    );
    driversStakeValues.push(driver.stakeValue);
    driversPercents.push(driver?.percent);
  });
  tmpValueDrivers.sort((a, b) => b[4] - a[4]);
  tmpValueDrivers.forEach((vd) => {
    VCPValueDriversData.push(vd, ...tmpKeyRoles.filter((kr) => kr[1] === vd[1]));
  });
  VCPValueDriversData.unshift([
    '',
    'Overall',
    '',
    '',
    _sum(driversStakeValues),
    `${_sum(driversPercents)}%`,
    data.overview.score,
    data.confidenceLevels.riskToHit,
  ]);

  VCPValueDriversData.push(
    [],
    [
      'The highlighted data represents a comprehensive overview of the value drivers and their associated key roles. It includes the weight assigned to each value driver in relation to the VCP and the weight assigned to each key role within the context of the value driver.',
    ],
  );
  return { VCPValueDriversHeaders, VCPValueDriversData };
};

const getVCPKeyRolesSheet = (data) => {
  const VCPKeyRolesHeaders = [
    'Type',
    'Department/Key role',
    'Value at stake ($M)',
    'Weight on VCP',
    'OHB score',
    'Confidence to deliver',
    'eNPS',
  ];

  const VCPKeyRolesData = [];
  const departmentStakeValues = [];
  const departmentPercent = [];

  const rolesByDepartment = groupBy(data?.confidenceLevels?.roles, 'department');
  const VCPKeyRolesBoldRows = [];
  const tmpVCPDepartments = [];
  Object.keys(rolesByDepartment).forEach((department) => {
    const departmentData =
      data?.confidenceLevels?.departments.find((d) => d.name === department) ?? {};
    if (Object.keys(departmentData).length === 0) {
      departmentData.stakeValue = Math.round(
        _sum(rolesByDepartment[department].map((r) => Math.round(r?.stakeValue) ?? 0)),
      );
      departmentData.percent = Math.round(
        _sum(rolesByDepartment[department].map((r) => r?.rolePercent ?? 0)),
      );
    }
    tmpVCPDepartments.push([
      'Department',
      department,
      toNumeric(departmentData?.stakeValue),
      departmentData?.percent ? `${departmentData?.percent}%` : '',
      departmentData?.score,
      departmentData?.confidenceLevel,
      departmentData?.eNPS,
    ]);

    departmentStakeValues.push(toInt(departmentData?.stakeValue) ?? 0);
    departmentPercent.push(toInt(departmentData?.percent) ?? 0);
  });

  tmpVCPDepartments
    .sort((a, b) => b[2] - a[2])
    .forEach((department) => {
      VCPKeyRolesData.push(department);
      VCPKeyRolesBoldRows.push(VCPKeyRolesData.length + 2);
      rolesByDepartment[department[1]].forEach((role) => {
        VCPKeyRolesData.push([
          'Key role',
          role?.role,
          `${toNumeric(toInt(role?.stakeValue))}`,
          `${Math.round(role?.rolePercent)}%`,
          '',
          '',
          '',
        ]);
      });
    });

  VCPKeyRolesData.unshift([
    '',
    'Overall',
    _sum(departmentStakeValues),
    `${Math.round(_sum(departmentPercent))}%`,
    data.overview.score,
    data.confidenceLevels.riskToHit,
    data.scores.npsQuestion?.score,
  ]);

  VCPKeyRolesData.push(
    [],
    [
      'The highlighted data above displays a comprehensive list of departments that impact the value creation plan, along with the key roles associated with each department. It also indicates the weights assigned to each department and key role in relation to the value creation plan.',
    ],
  );

  return { VCPKeyRolesHeaders, VCPKeyRolesData, VCPKeyRolesBoldRows };
};

const getVCPOverScoresSheet = (data) => {
  const OSHeaders = [
    'Type',
    'Category/Question',
    'Key terms',
    'Score',
    'Value at stake ($M)',
    'Weight on VCP',
    'Benchmark (Top quartile)',
    'Benchmark (Average)',
  ];

  const OSData = [];

  const questions = data?.fixedAndNumerical?.questions;
  const driverQuestions = data?.fixedAndNumericalByDriver?.questions;
  const categories = data?.fixedAndNumericalByDriver?.categories;
  const maturityKeyObjectives = data?.maturityKeyObjectives;
  if (data?.confidenceLevels.drivers?.length) {
    const driversConfLevels = [];
    data?.confidenceLevels.drivers.forEach((driver) => {
      const questionByDriver = Object.values(data.rawData.questions).find(
        (q) => q.keyTerm === driver.keyTerm && q.typeId === QUESTIONS_TYPES.FIXED,
      );
      driversConfLevels.push(driver?.confidenceLevel ?? null);
      if (questionByDriver) {
        OSData.push([
          'Question',
          questionByDriver?.text,
          questionByDriver?.keyTerm,
          driver?.confidenceLevel,
        ]);
      }
    });
    OSData.unshift(['Category', 'Confidence to deliver', '', data.confidenceLevels.riskToHit]);
  }

  const formattedCategories = categories?.map((category) => {
    const categoryQuestionsByDriver = driverQuestions.filter((dq) => category.id === dq.categoryId);
    const questionsByCategory = questions.filter((q) =>
      categoryQuestionsByDriver.map((cq) => cq.id).includes(q.id),
    );
    questionsByCategory.forEach((question) => {
      const keyObj = maturityKeyObjectives.find((obj) => obj.keyTerm === question.keyTerm);
      question.valueAtStake = keyObj?.valueAtStake;
    });
    category.questions = questionsByCategory;
    return category;
  });

  const OSCategoryPercents = [];
  const totalValueAtStake = _sum(
    map(formattedCategories, 'id').map(
      (id) => _find(data?.confidenceLevels?.drivers, { id })?.stakeValue,
    ),
  );
  formattedCategories.forEach((category) => {
    OSData.push([
      'Category',
      category?.name,
      '',
      category?.score,
      _find(data?.confidenceLevels.drivers, { id: category.id })?.stakeValue,
      `${category?.percent}%`,
      category?.minQ1,
      category?.minQ2,
    ]);
    OSCategoryPercents.push(category?.percent);

    category?.questions.forEach((question) => {
      const VSPercent = (question?.valueAtStake / totalValueAtStake) * 100;
      OSData.push([
        'Question',
        question?.text,
        question?.keyTerm,
        question?.score,
        question?.valueAtStake,
        toPercent(VSPercent.toFixed(1)),
        question?.minQ1,
        question?.minQ2,
      ]);
    });
  });

  OSData.unshift([
    '',
    'Overall',
    '',
    `${data.overview.score}`,
    `${totalValueAtStake}`,
    `${_sum(OSCategoryPercents)}%`,
    data.scores.minQ1,
    data.scores.minQ2,
  ]);

  OSData.push(
    [],
    [
      'The data highlighted shows the answer distribution of the key roles for the key org capabilities (questions) impacting the Value Creation Plan.',
    ],
  );

  return { OSHeaders, OSData };
};

const getVCPOrgCapSheet = (data, answerDistributionDisplayMode) => {
  const orgCapHeaders = [
    'Type',
    'Category/Question',
    'Key terms',
    'Strongly Disagree',
    'Disagree',
    'Somewhat Disagree',
    'Somewhat Agree',
    'Agree',
    'Strongly Agree',
  ];
  const columnCount = 6;

  const getAnswers = (question) => {
    const answers = [];
    [...Array(columnCount).keys()].forEach((val, index) => {
      const answer = question?.answerOptions.filter((elem) => elem.id === index + 1);
      if (answerDistributionDisplayMode === 'percent') {
        answers[index] = answer.length > 0 ? `${answer[0].score}%` : '';
      } else {
        answers[index] = answer.length > 0 ? `${answer[0].count}` : '';
      }
    });
    return answers;
  };

  const formattedQuestions = data?.fixedAndNumericalByDriver?.categories?.map((category) => {
    const questionsIds = data?.fixedAndNumericalByDriver?.questions
      .filter((dq) => category.id === dq.categoryId)
      .map((q) => q.id);
    const questionsByCategory = data?.fixedAndNumerical?.questions.filter((q) =>
      questionsIds.includes(q.id),
    );
    return questionsByCategory;
  });
  const uniqueFormattedQuestions = uniqBy(formattedQuestions.flat(), 'keyTerm');
  const orgCapData = toArray(data?.fixedBreakdown?.categories)
    .map((category) => {
      const categoryCalculations = [];
      const questionsRow = uniqueFormattedQuestions
        .filter((item) => item.categoryId === category.id)
        .map((question) => {
          const answers = getAnswers(
            data?.fixedBreakdown?.questions.find((q) => q.id === question.id),
          );
          categoryCalculations.push(answers);
          return ['Question', question.text, question.keyTerm, ...answers.reverse()];
        });
      if (questionsRow.length) {
        const tmpData = [
          [
            'Category',
            category.name,
            '',
            ...calculateCategoryValues(
              categoryCalculations,
              answerDistributionDisplayMode === 'percent',
            ),
          ],
        ];
        tmpData.push(...questionsRow);
        return tmpData;
      }
      return [];
    })
    .flat();
  const orgBoldRows = orgCapData.reduce(function (a, e, i) {
    if (e[0] === 'Category') a.push(i + 2);
    return a;
  }, []);

  orgCapData.push(
    [],
    [
      'The data highlighted shows the answer distribution of the key roles for the key org capabilities (questions) impacting the Value Creation Plan.',
    ],
  );
  return { orgCapHeaders, orgCapData, orgBoldRows };
};

const getVCPTopCommentsSheet = (data) => {
  const topCommentsHeader = ['Question', 'Key term', 'Category', 'Statement'];
  if (data?.filters?.allowRanking) {
    topCommentsHeader.push('Score');
  }
  const topCommentsData = [];
  data.confidenceLevels.drivers.forEach((driver) => {
    const question = Object.values(data.rawData.questions).find(
      (q) => q.keyTerm === driver.keyTerm && q.typeId === QUESTIONS_TYPES.STATEMENT,
    );
    const statements = driver?.comments?.map((comment) => [
      question?.text,
      question?.keyTerm,
      data.rawData.categories[question?.categoryId],
      comment?.text,
      data?.filters?.allowRanking ? 5 : null,
    ]);
    topCommentsData.push(...(statements ?? []));
  });
  data?.topStatements.forEach((statement) => {
    statement?.questions.forEach((question) => {
      sortBy(question?.topics, ['score'])
        .reverse()
        .forEach((topic) => {
          topCommentsData.push([
            question?.text,
            question?.keyTerm,
            statement?.name,
            topic?.text,
            data?.filters?.allowRanking ? topic?.score : null,
          ]);
        });
    });
  });

  topCommentsData.push(
    [],
    [
      'The statements presented above highlight the comments provided by key roles regarding your Value Creation Plan (VCP).',
    ],
  );

  return { topCommentsHeader, topCommentsData };
};

export function exportVCP(data, workbook, answerDistributionDisplayMode) {
  // VCP Value Drivers Sheet
  const { VCPValueDriversHeaders, VCPValueDriversData } = getVCPValueDriversSheet(data);
  const ws = XLSX.utils.aoa_to_sheet([VCPValueDriversHeaders, ...VCPValueDriversData]);
  const colCount = VCPValueDriversHeaders.length || 1;
  setColWidth(ws, [150, ...Array(colCount - 3).fill(300), 150, 150]);
  colorFirstRow(ws, colCount - 1);
  freezeFirstRow(ws);
  [2, VCPValueDriversData.length + 1].forEach((row) => {
    boldRow(ws, colCount, row);
  });
  VCPValueDriversData.forEach((item, index) => {
    if (item[0] === 'Value driver') {
      if (item[6].length) {
        styleCell(ws, index + 2, 6, {
          fgColor: { rgb: getHeatmapColor(item[6], 0, 100, undefined, true) },
        });
      }
      if (item[7].length) {
        styleCell(ws, index + 2, 7, {
          fgColor: { rgb: getHeatmapColor(item[7], 0, 100, undefined, true) },
        });
      }
      [0, 1, 4, 5].forEach((colIndex) => {
        styleCell(ws, index + 2, colIndex, { bold: true });
      });
    }
  });
  [4, 5, 6, 7].forEach((colIndex) => {
    alignColumn(ws, toArray(VCPValueDriversData).length + 1, colIndex, { horizontal: 'right' });
    alignColumn(ws, 1, colIndex, { horizontal: 'left' });
  });
  ws['!autofilter'] = { ref: `A1:H${VCPValueDriversData.length - 1}` };
  XLSX.utils.book_append_sheet(workbook, ws, 'VCP value drivers');

  // Key Roles & Departments Sheet

  const { VCPKeyRolesHeaders, VCPKeyRolesData, VCPKeyRolesBoldRows } = getVCPKeyRolesSheet(data);

  const KRws = XLSX.utils.aoa_to_sheet([VCPKeyRolesHeaders, ...VCPKeyRolesData]);

  const KRColCount = VCPValueDriversHeaders.length || 1;
  setColWidth(KRws, [150, 250, ...Array(KRColCount - 3).fill(150)]);
  colorFirstRow(KRws, KRColCount - 2);
  freezeFirstRow(KRws);
  [2, VCPKeyRolesData.length + 1].forEach((row) => {
    boldRow(KRws, KRColCount, row);
  });

  VCPKeyRolesBoldRows.forEach((row) => {
    range(0, VCPKeyRolesHeaders.length).forEach((colIndex) => {
      styleCell(KRws, row, colIndex, { bold: true });
    });
  });

  [2, 3, 4, 5, 6].forEach((colIndex) => {
    alignColumn(KRws, toArray(VCPKeyRolesData).length + 1, colIndex, { horizontal: 'right' });
    alignColumn(KRws, 1, colIndex, { horizontal: 'left' });
  });
  KRws['!autofilter'] = { ref: `A1:G${VCPValueDriversData.length - 2}` };
  XLSX.utils.book_append_sheet(workbook, KRws, 'VCP key roles & departments');

  // Overall scores

  const { OSHeaders, OSData } = getVCPOverScoresSheet(data);
  const OSws = XLSX.utils.aoa_to_sheet([OSHeaders, ...OSData]);
  const OSColCount = OSHeaders.length || 1;
  setColWidth(OSws, [150, 650, 250, ...Array(OSColCount - 3).fill(200)]);
  colorFirstRow(OSws, OSColCount - 1);
  freezeFirstRow(OSws);

  range(0, OSColCount).forEach((colIndex) => {
    OSData.forEach((v, index) => {
      if (v.length > 0 && (v[0]?.toLowerCase() === 'category' || !v[0].length)) {
        styleCell(OSws, index + 2, colIndex, { bold: true });
      }
    });
  });

  styleCell(OSws, OSData.length + 1, 0, { bold: true });

  [3, 4, 5, 6, 7].forEach((colIndex) => {
    alignColumn(OSws, toArray(OSData).length + 1, colIndex, { horizontal: 'right' });
    alignColumn(OSws, 1, colIndex, { horizontal: 'left' });
  });

  OSData.forEach((v, index) => {
    if (v.length > 0 && v[0].toLowerCase().length > 0 && v[3]?.toString().length > 0) {
      styleCell(OSws, index + 2, 3, {
        fgColor: { rgb: getHeatmapColor(v[3] ?? 0, 0, 100, undefined, true) },
        bold: v[0].toLowerCase() === 'category',
      });
    }
  });

  XLSX.utils.book_append_sheet(workbook, OSws, 'VCP overall scores');

  // VCP org capabilities Ans Distr.

  const { orgCapHeaders, orgCapData, orgBoldRows } = getVCPOrgCapSheet(
    data,
    answerDistributionDisplayMode,
  );
  const orgCapws = XLSX.utils.aoa_to_sheet([orgCapHeaders, ...orgCapData]);
  const orgColCount = orgCapHeaders.length || 1;
  setColWidth(orgCapws, [150, 650, 250, ...Array(orgColCount - 3).fill(200)]);
  colorFirstRow(orgCapws, orgColCount - 1);
  freezeFirstRow(orgCapws);
  orgBoldRows.forEach((row) => {
    [...Array(orgColCount + 1).keys()].forEach((colIndex) => {
      styleCell(orgCapws, row, colIndex, { bold: true });
    });
  });
  [orgCapData.length + 1].forEach((row) => {
    boldRow(orgCapws, colCount, row);
  });
  [3, 4, 5, 6, 7, 8].forEach((colIndex) => {
    alignColumn(orgCapws, toArray(orgCapData).length + 1, colIndex, { horizontal: 'right' });
    alignColumn(orgCapws, 1, colIndex, { horizontal: 'left' });
  });
  XLSX.utils.book_append_sheet(workbook, orgCapws, 'VCP answer distribution');

  // VCP Top Comments
  const { topCommentsHeader, topCommentsData } = getVCPTopCommentsSheet(data);
  if (topCommentsData.length > 2) {
    const topCommentsws = XLSX.utils.aoa_to_sheet([topCommentsHeader, ...topCommentsData]);
    const topCommentsCount = topCommentsHeader.length || 1;
    setColWidth(topCommentsws, [650, 200, 200, 650, 100]);
    colorFirstRow(topCommentsws, topCommentsCount - 1);
    freezeFirstRow(topCommentsws);

    [topCommentsData.length + 1].forEach((row) => {
      boldRow(topCommentsws, topCommentsCount, row);
    });

    XLSX.utils.book_append_sheet(workbook, topCommentsws, 'VCP all comments');
  }
}

export function export360View(data, fileName, params) {
  const { compareEnabled, exportedColumnKeys } = params;
  const workbook = XLSX.utils.book_new();
  const result = getPortfolioOverviewSheet(data, params);
  const ws = XLSX.utils.aoa_to_sheet(result.sheetData);
  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;
  setColWidth(ws, [400, ...Array(result.sheetData[0].length - 1).fill(80)]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);
  result.boldRows.forEach((row) => {
    boldRow(ws, colCount, row);
  });
  result.cellsToColor.forEach((item) => {
    styleCell(ws, item.row, item.col, item);
  });
  XLSX.utils.book_append_sheet(workbook, ws, '360 Team View');

  const statementsData = [];
  const staticRowCodes = [
    'participation',
    'respondents',
    'participants',
    'eNPSScore',
    'overallScore',
  ];
  const resultForStatements = getPortfolioOverviewSheet(
    {
      ...data,
      rows: [
        ...data.rows.filter(({ code }) => staticRowCodes.includes(code)),
        { name: 'Open Comments', isBold: true },
        ...data.statementsByDimension,
      ],
    },
    params,
  );
  statementsData.push(...resultForStatements.sheetData);
  const wsFeedBack = XLSX.utils.aoa_to_sheet(statementsData);
  const rangeFeedback = XLSX.utils.decode_range(wsFeedBack['!ref']);
  const colCountFeedback = rangeFeedback?.e?.c || 1;
  const staticColumnsCnt =
    exportedColumnKeys.filter((key) => ['spread', 'average', 'benchmark'].includes(key)).length *
    (compareEnabled ? 2 : 1);
  setColWidth(wsFeedBack, [
    200,
    ...Array(staticColumnsCnt).fill(80),
    ...Array(resultForStatements.sheetData[0].length - staticColumnsCnt - 1).fill(200),
  ]);
  colorFirstRow(wsFeedBack, colCountFeedback);
  freezeFirstRow(wsFeedBack);
  resultForStatements.boldRows.forEach((row) => {
    boldRow(wsFeedBack, colCountFeedback, row);
  });
  resultForStatements.cellsToColor.forEach((item) => {
    styleCell(wsFeedBack, item.row, item.col, item);
  });
  resultForStatements.wrapRows.forEach((row) => {
    wrapRow(wsFeedBack, colCountFeedback, row);
  });
  XLSX.utils.book_append_sheet(workbook, wsFeedBack, 'Feedback');

  if (data.manager360Reviews?.length > 0) {
    const managerStaticRowCodes = [
      'participation',
      'respondents',
      'participants',
      'eNPSScore',
      'overallScore',
    ];
    const managerReviewData = [];
    const resultForManagers = getPortfolioOverviewSheet(
      {
        ...data,
        rows: [...data.rows.filter(({ code }) => managerStaticRowCodes.includes(code))],
      },
      params,
    );
    resultForManagers.sheetData.forEach((d, dataIndex) => {
      if (!dataIndex) {
        d.splice(1, 0, 'Manager name');
      } else {
        d.splice(1, 0, undefined);
      }
    });
    const getText = (val, colID) => {
      const elemIndex = val.findIndex((ch) => Object.keys(ch)[0] === colID);
      if (elemIndex > -1) {
        return Object.values(val[elemIndex])[0]
          .current.map((c) => c.text)
          .toString();
      }
      return undefined;
    };

    const columnsDiff = difference(params.exportedColumnKeys, [
      ...data.columns.map(({ id }) => id),
      ...['rowControls', 'name'],
    ]);
    resultForManagers.sheetData.push(
      ...data.manager360Reviews.map((review) => [
        review.rowType,
        review.managerName,
        ...columnsDiff.map(() => undefined),
        ...data.columns.map(({ id }) => id).map((colID) => getText(review.children, colID)),
      ]),
    );
    resultForManagers.wrapRows.push(
      ...range(managerStaticRowCodes.length, resultForManagers.sheetData.length + 1),
    );

    resultForManagers.sheetData.forEach((sd, index) => {
      if (sd[0] === 'Manager rating') resultForManagers.boldRows.push(index + 1);
    });

    managerReviewData.push(...resultForManagers.sheetData);

    const wsManagersReview = XLSX.utils.aoa_to_sheet(managerReviewData);
    const rangeManagersReview = XLSX.utils.decode_range(wsManagersReview['!ref']);
    const colCountManagersReview = rangeManagersReview?.e?.c || 1;
    colorFirstRow(wsManagersReview, colCountManagersReview);
    freezeFirstRow(wsManagersReview);
    const managerStaticColumnsCnt =
      exportedColumnKeys.filter((key) => ['spread', 'average', 'benchmark', 'name'].includes(key))
        .length * (compareEnabled ? 2 : 1);
    setColWidth(wsManagersReview, [
      200,
      ...Array(managerStaticColumnsCnt).fill(120),
      ...data.columns.fill(350),
    ]);
    resultForManagers.boldRows.forEach((row) => {
      boldRow(wsManagersReview, colCountManagersReview, row);
      range(4, colCountManagersReview + 1).forEach((colIndex) => {
        styleCell(wsManagersReview, row, colIndex, {
          alignment: { horizontal: 'right' },
          bold: true,
        });
      });
    });
    resultForManagers.cellsToColor.forEach((item) => {
      styleCell(wsManagersReview, item.row, item.col + 1, item);
    });

    resultForManagers.wrapRows.forEach((row) => {
      wrapRow(wsManagersReview, colCountManagersReview, row);
    });

    boldCol(wsManagersReview, resultForManagers.sheetData.length, 0);
    XLSX.utils.book_append_sheet(workbook, wsManagersReview, 'Manager review');
  }

  const rsHeader = [
    [...['Question Category', 'Key Term', 'Question'], ...data.columns.map((c) => c.fullNameRaw)],
  ];

  const rsData = [];

  if (toArray(data.statementsByFollowingQuestions).length && data.columns?.length) {
    const rsRowsToColor = [1];
    toArray(data.statementsByFollowingQuestions).forEach((statement) => {
      if (toArray(statement.children).length) {
        let tmpData = [statement.category, statement.keyTerm, statement.name];
        statement.children
          .filter((ch) => data.columns.map((c) => c.id).includes(Object.keys(ch)[0]))
          .forEach((question) => {
            tmpData = tmpData.concat([
              Object.values(question)[0]
                .current.map((c) => c.text)
                .join(';\n '),
            ]);
          });
        rsData.push(tmpData);
      }
    });
    const rsStrength = XLSX.utils.aoa_to_sheet([
      ...rsHeader,
      ...sortBy(rsData, [
        function (r) {
          return r[0];
        },
      ]).reverse(),
    ]);
    const rsRangeQuestions = XLSX.utils.decode_range(rsStrength['!ref']);
    const rsColCountQuestions = rsRangeQuestions?.e?.c || 1;
    rsRowsToColor.forEach((row) => {
      colorRow(rsStrength, rsColCountQuestions, row);
    });
    colorFirstRow(rsStrength, rsColCountQuestions - 1);
    freezeFirstRow(rsStrength);
    setColWidth(rsStrength, [200, 200, 200, ...data.columns.fill(1000)]);
    range(1, rsData.length + 2).forEach((row) =>
      wrapCenterRow(rsStrength, data.columns.length + 3, row),
    );
    XLSX.utils.book_append_sheet(workbook, rsStrength, 'RS Strengths & AD Feedback');
  }

  const questionsData = [['Question Text', 'Key Terms']];
  const rowsToColor = [];
  toArray(data.surveyQuestions).forEach((category) => {
    if (toArray(category.children).length) {
      questionsData.push([category.name, category.name]);
      rowsToColor.push(questionsData.length);
      category.children.forEach((question) => {
        questionsData.push([question.text, question.name]);
      });
    }
  });
  const wsQuestions = XLSX.utils.aoa_to_sheet(questionsData);
  const rangeQuestions = XLSX.utils.decode_range(wsQuestions['!ref']);
  const colCountQuestions = rangeQuestions?.e?.c || 1;
  rowsToColor.forEach((row) => {
    colorRow(wsQuestions, colCountQuestions, row);
  });
  freezeFirstRow(wsQuestions);
  setColWidth(wsQuestions, [400, 200]);
  XLSX.utils.book_append_sheet(workbook, wsQuestions, 'Questions & Keyterms');

  saveToExcel(workbook, fileName);
}

export async function export360ParticipantsFile(participants, managers, fileName) {
  const templateWb = await getTemplate(`${window.location.origin}/xlsx/participants360.xlsx`);

  const headers = [
    'Recipient First Name',
    'Recipient Last Name',
    'Recipient Email',
    'Provider First Name',
    'Provider Last Name',
    'Provider Email',
    'Relationship',
    'Manager review (optional)',
  ];
  const data = [];
  participants.forEach((user) => {
    user.children.forEach((provider) => {
      data.push([
        user.firstName,
        user.lastName,
        user.email,
        provider.firstName,
        provider.lastName,
        provider.email,
        provider.type,
        managers?.some((item) => {
          if (item.email === provider.email) {
            return item.recipients.some((recipient) => recipient.email === user.email);
          }
          return false;
        })
          ? 'Yes'
          : '',
      ]);
    });
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [200, 200, 200, 200, 200, 200, 200, 200]);
  colorFirstRow(ws, colCount - 1);
  colorCell(ws, 7, 1, 0xeaf1dd);
  freezeFirstRow(ws);

  const dataSheetName = '360 Participants';
  XLSX.utils.book_append_sheet(workbook, ws, dataSheetName);

  const sheetName = templateWb.SheetNames[0];
  XLSX.utils.book_append_sheet(workbook, templateWb.Sheets[sheetName], sheetName);
  workbook.SheetNames = [sheetName, dataSheetName];

  saveToExcel(workbook, fileName);
}

export async function exportSurvey(surveys, fileName = 'Surveys') {
  const headers = [
    'Organization',
    'Portfolio',
    'Survey',
    'Participation',
    'Respondents',
    'Participants',
    'Launch Date',
    'Reminder Date',
    'Close Date',
    'Score',
    'eNPS',
  ];

  const data = [];
  surveys.forEach((item) => {
    data.push([
      item.organizationName,
      item.portfolioName?.map((el) => el?.name).join(', '),
      item.name,
      isSet(item.participation) ? `${item.participation}%` : '',
      isSet(item.respondents) ? `${item.respondents}` : '',
      isSet(item.participants) ? `${item.participants}` : '',
      isSet(item.launchDate) ? moment(item.launchDate).format(DEFAULT_UI_DATE_FORMAT) : '',
      isSet(item.reminderDate) ? moment(item.reminderDate).format(DEFAULT_UI_DATE_FORMAT) : '',
      isSet(item.closeDate) ? moment(item.closeDate).format(DEFAULT_UI_DATE_FORMAT) : '',
      isSet(item.score) ? `${item.score}%` : '',
      isSet(item.enpsScore) ? `${item.enpsScore}` : '',
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [300, 300, 300, 100, 100, 100, 100, 100, 100, 100, 100]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, fileName);

  saveToExcel(workbook, fileName);
}

export function exportOrganizations(organizations, fileName = 'Organizations') {
  const headers = [
    'Name',
    'URL',
    'Industries',
    'Sub-industries',
    'Age of the company',
    'Annual revenue',
    'Number of employees',
    'Headquarters country',
    'Headquarters state',
    'Portfolios',
    'Demo',
  ];
  const data = [];
  organizations.forEach((item) => {
    data.push([
      item.orgname,
      item.url,
      item.sectorsText,
      item.industriesText,
      item.companyAgeText,
      item.annualRevenueText,
      item.employeesCountText,
      item.headquartersCountryText,
      item.headquartersStateText,
      item.portfoliosText,
      item.demo === 0 ? '' : 'Yes',
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [300, 300, 400, 400, 145, 220, 180, 180, 160, 400, 60]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, fileName);

  saveToExcel(workbook, fileName);
}

export async function exportDEIData(data, surveyName) {
  const workbook = XLSX.utils.book_new();
  const staticSheets = data.filter((i) => i.static);
  const fileName = `Data - ${surveyName}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const sheet of staticSheets) {
    // eslint-disable-next-line no-await-in-loop
    sheet.wb = await getTemplate(sheet.url);
  }
  data.forEach((sheet) => {
    if (sheet.static) {
      const name = toArray(sheet.wb?.SheetNames)[0];
      const resSheet = sheet.wb.Sheets[name];
      if (toArray(sheet.placeholders).length) {
        sheet.placeholders.forEach((item) => {
          const cell = resSheet[item.cell];
          if (cell) {
            if (item.key === '[Filename]') {
              item.value = fileName;
            }
            cell.v = toString(cell.v).replaceAll(item.key, item.value);
            cell.w = toString(cell.w).replaceAll(item.key, item.value);
            cell.h = toString(cell.h).replaceAll(item.key, item.value);
          }
        });
      }
      XLSX.utils.book_append_sheet(workbook, resSheet, sheet.sheetName);
    } else {
      const ws = XLSX.utils.aoa_to_sheet(sheet.data);

      const decodeRange = XLSX.utils.decode_range(ws['!ref']);
      const colCount = decodeRange?.e?.c || 1;
      const rowCount = decodeRange?.e?.r || 1;

      colorFirstRow(ws, colCount);
      freezeFirstRow(ws);
      setColWidth(ws, toArray(sheet.columnsWidth));
      toArray(sheet.boldRows).forEach((row) => {
        boldRow(ws, colCount, row);
      });
      toArray(sheet.boldCols).forEach((col) => {
        boldCol(ws, rowCount, col);
      });
      toArray(sheet.styleCells).forEach((item) => {
        styleCell(ws, item.r, item.c, item);
      });
      toArray(sheet.formatCells).forEach((item) => {
        formatCell(ws, item.r, item.c, item.format);
      });
      toArray(sheet.centerAlignedColumns).forEach((col) => {
        alignColumn(ws, toArray(sheet.data).length, col, { horizontal: 'center' });
      });
      toArray(sheet.leftAlignedColumns).forEach((col) => {
        alignColumn(ws, toArray(sheet.data).length, col, { horizontal: 'left' });
      });
      XLSX.utils.book_append_sheet(workbook, ws, sheet.sheetName);
    }
  });

  saveToExcel(workbook, fileName);
}

export function export360ManagersProgress(list, fileName = 'Managers progress') {
  const headers = ['Review', 'Manager'];
  const questions = new Set();
  list.forEach((i) => {
    questions.add(i.questionStringval);
  });
  questions.forEach((q) => {
    headers.push(q);
  });
  const result = [];
  list.forEach((i) => {
    const item = result.find(
      (r) => r.managerId === i.managerId && r.targetUserId === i.targetUserId,
    );
    if (item) {
      item[i.questionStringval] =
        isSet(i.questionAnswerValue) && i.questionAnswerValue !== '' ? 'Yes' : 'No';
    } else {
      result.push({
        [i.questionStringval]:
          isSet(i.questionAnswerValue) && i.questionAnswerValue !== '' ? 'Yes' : 'No',
        ...i,
      });
    }
  });
  const data = [];
  result.forEach((i) => {
    const row = [
      `${i.targetUserFirstName || ''} ${i.targetUserLastName || ''}`.trim(),
      `${i.managerFirstName || ''} ${i.managerLastName || ''}`.trim(),
    ];
    questions.forEach((q) => {
      row.push(i[q]);
    });
    data.push(row);
  });

  const workbook = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const decodeRange = XLSX.utils.decode_range(ws['!ref']);
  const colCount = decodeRange?.e?.c || 1;

  setColWidth(ws, [300, 300, 400, 400, 400]);
  colorFirstRow(ws, colCount);
  freezeFirstRow(ws);

  XLSX.utils.book_append_sheet(workbook, ws, 'Results');

  saveToExcel(workbook, fileName);
}
