import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { faDownload, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Modal, Row, Table } from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { SURVEY_TYPE } from '../../../constants';
import { FiltersContext } from '../../../store/filters';
import { UserContext } from '../../../store/user';
import { showError } from '../../../utils/alerts';
import { post } from '../../../utils/backend';
import { showTableTotal, toArray } from '../../../utils/helpers';
import { getSurveysTree } from '../../../utils/survey';
import { filterSurveysByName } from '../../../utils/surveys/surveyUtils';
import { export360ManagersProgress } from '../../../utils/xlsx';
import Loader from '../../Loader';
import Search from '../../Search';
import PreviewModal from '../Preview';
import RespondentsModal from '../Respondents';

import './styles.scss';

const ManageSurveys = React.memo(({ visible, onCancel, surveys, loading }) => {
  const { user } = useContext(UserContext);
  const { clearFilters } = useContext(FiltersContext);
  const [searchText, setSearchText] = useState('');
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [previewModalSettings, setPreviewModalSettings] = useState({});
  const [respondentsModalSettings, setRespondentsModalSettings] = useState({});
  const [progressExporting, setProgressExporting] = useState();

  const history = useHistory();

  function closePreviewModal() {
    setPreviewModalSettings({});
  }

  function openPreviewModal(survey) {
    setPreviewModalSettings({
      visible: true,
      surveyId: survey.id,
      instanceId: survey.surveyInstanceId,
      isReminder: survey.isRunning,
    });
  }

  function openRespondents(survey) {
    setRespondentsModalSettings({
      visible: true,
      surveyId: survey.id,
      instanceId: survey.surveyInstanceId,
      surveyName: survey.surveyname,
      surveyType: survey.type,
    });
  }

  function goToReview(item) {
    clearFilters();
    onCancel();
    history.push(`/review?survey=${item.id}&status=4&group=all`);
  }

  function exportManagerProgress(survey) {
    setProgressExporting(survey.id);
    const inctanceIds = surveys
      .filter((s) => s.parentID360 === survey.id)
      .map((s) => s.surveyInstanceId);
    post('/answers/get_managers_answers', inctanceIds)
      .then((res) => {
        export360ManagersProgress(toArray(res), `${survey.surveyname} - Managers progress`);
      })
      .catch((e) => showError(e.message))
      .finally(() => setProgressExporting());
  }

  function isBeforeDeadline(survey) {
    return moment().isBefore(moment(survey.last_close).add(1, 'days'));
  }

  const columns = [
    {
      title: 'Survey',
      filterMode: 'tree',
      dataIndex: 'surveyname',
      key: 'surveyname',
      className: 'first-column',
      width: '70%',
      sorter: (a, b) => a.surveyname.trim().localeCompare(b.surveyname.trim()),
      render: (value, survey) => (
        <span className={survey.parentID360 ? 'child' : 'parent'}>
          {!survey.parentID360 ? <b>{survey.surveyname}</b> : <span>{survey.surveyname}</span>}:{' '}
          {survey.recipients ? Math.round((survey.participants / survey.recipients) * 100) : 0}%
          participation&nbsp;
          {survey.participants}/{survey.recipients}
          {survey.last_close && survey.isRunning && (
            <>
              &nbsp;-&nbsp;
              {moment().isAfter(moment(survey.last_close).add(1, 'days')) ? 'closed ' : 'closing '}
              {moment().to(moment.tz(survey.last_close, 'America/Los_Angeles').add(1, 'days'))}
              &nbsp;
            </>
          )}
          {survey.isRunning && survey.reviewStatementsCount > 0 && (
            <Button type="primary" ghost size="small" onClick={() => goToReview(survey)}>
              Review Statements ({survey.reviewStatementsCount})
            </Button>
          )}
          {user.isAdmin && survey.type === 'comm' && !!survey.commentQuestionsCount && (
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => exportManagerProgress(survey)}
              loading={progressExporting === survey.id}
              icon={<Icon icon={faDownload} className="btn-icon" />}
            >
              Review managers progress
            </Button>
          )}
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'id',
      width: '30%',
      render: (value, survey) => (
        <>
          <Row gutter={[8, 0]} className="no-wrap" align="middle" justify="end">
            {isBeforeDeadline(survey) &&
              (survey.type !== SURVEY_TYPE.CHILD_360 || !survey.isRunning) && (
                <Col>
                  <Button type="default" size="small" onClick={() => openPreviewModal(survey)}>
                    {survey.isRunning ? 'Preview & Send reminder' : 'Preview & Run'}
                  </Button>
                </Col>
              )}
            <Col>
              <Button
                type="default"
                size="small"
                onClick={() => openRespondents(survey)}
                icon={<Icon icon={faUser} />}
              />
            </Col>
            <Col>
              <Dropdown
                menu={{
                  onClick: ({ key }) => {
                    if (key === 'editSurvey') {
                      onCancel();
                    }
                    if (key === 'previewAndSend') {
                      openPreviewModal(survey);
                    }
                  },
                  items: [
                    {
                      label: <Link to={`/configure/surveys/edit/${survey.id}`}>Edit survey</Link>,
                      key: 'editSurvey',
                    },
                    ...(isBeforeDeadline(survey) &&
                    survey.type === SURVEY_TYPE.CHILD_360 &&
                    survey.isRunning
                      ? [
                          {
                            label: 'Preview & Send reminder',
                            key: 'previewAndSend',
                          },
                        ]
                      : []),
                  ],
                }}
              >
                <Button size="small" icon={<Icon icon={faWrench} />} />
              </Dropdown>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  useEffect(() => {
    const list = getSurveysTree(surveys);
    if (searchText.length > 0) {
      setFilteredSurveys(filterSurveysByName(list, searchText));
    } else {
      setFilteredSurveys(list);
    }
  }, [searchText, surveys]);

  useEffect(() => {
    if (!visible) {
      closePreviewModal();
    }
  }, [visible]);
  return (
    <Modal
      className="survey-manage"
      title="Manage Current Surveys"
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={900}
      afterClose={() => setSearchText('')}
    >
      {loading && <Loader />}
      <Search
        style={{ margin: 0 }}
        value={searchText}
        onChange={setSearchText}
        placeholder="Search surveys"
      />
      {filteredSurveys.length > 0 && (
        <Table
          size="small"
          rowKey="id"
          dataSource={filteredSurveys}
          columns={columns}
          showSorterTooltip={false}
          showHeader={false}
          pagination={{ showSizeChanger: true, showTotal: showTableTotal, defaultPageSize: 20 }}
        />
      )}
      <PreviewModal
        visible={previewModalSettings.visible}
        surveyId={previewModalSettings.surveyId}
        instanceId={previewModalSettings.instanceId}
        isReminder={previewModalSettings.isReminder}
        onCancel={closePreviewModal}
      />
      <RespondentsModal
        visible={respondentsModalSettings.visible}
        onCancel={() => setRespondentsModalSettings({})}
        instanceId={respondentsModalSettings.instanceId}
        surveyId={respondentsModalSettings.surveyId}
        surveyName={respondentsModalSettings.surveyName}
        surveyType={respondentsModalSettings.surveyType}
      />
    </Modal>
  );
});

ManageSurveys.defaultProps = {
  visible: false,
  onCancel: () => {},
  surveys: [],
  loading: false,
};

ManageSurveys.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      surveyname: PropTypes.string,
      recipients: PropTypes.number,
      last_close: PropTypes.string,
      participants: PropTypes.number,
      isRunning: PropTypes.bool,
      reviewStatementsCount: PropTypes.number,
      surveyInstanceId: PropTypes.number,
    }),
  ),
};

export default ManageSurveys;
