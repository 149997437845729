import React from 'react';
import { isNil } from 'lodash';
import moment from 'moment';

import { LOCALES } from '../config';
import {
  DEFAULT_API_FORMAT,
  EMAIL_TYPES_NAME,
  NSP_QUESTION_ANSWERS,
  QUESTIONS_ANSWERS,
  QUESTIONS_TYPES,
} from '../constants';

import { showError, showSuccess, showWarning } from './alerts';
import { post } from './backend';
import { cloneDeep, toArray, toString, validateEmails } from './helpers';

export const tooltipContentMessageCopy = (
  <div>
    <p>
      This button duplicates the communication from the parent survey to all child surveys. It saves
      time and ensures consistency when communicating with multiple recipients.
    </p>
    <p>Note:</p>
    <ul>
      <li>The parent survey is the main survey.</li>
      <li>Child surveys are created for each feedback recipient.</li>
    </ul>
  </div>
);

export function localesEmailWarning(emails, locales) {
  const warnings = [];
  emails.forEach((item) => {
    const missingLocales = [];
    locales.forEach((lang) => {
      if (lang === 'en' && !/@FirstName/i.test(toString(item[lang]?.body))) {
        missingLocales.push(LOCALES.find((i) => i.locale === lang).language);
      } else if (toString(item[lang]?.body).length && !/@FirstName/i.test(item[lang]?.body)) {
        missingLocales.push(LOCALES.find((i) => i.locale === lang).language);
      }
    });
    if (missingLocales.length) {
      warnings.push(
        `In ${item.name || ''} email body @FirstName is missing for ${missingLocales.join(', ')}`,
      );
    }
  });
  return warnings;
}

export function localesEmailValidator({ getFieldValue }, name, locales, field) {
  return {
    validator() {
      const item = getFieldValue([field, name]);
      const missingLocales = [];
      locales.forEach((lang) => {
        if (
          lang === 'en' &&
          !/@SurveyLink/i.test(toString(item[lang]?.body)) &&
          !/@OnDemandLink/i.test(toString(item[lang]?.body))
        ) {
          missingLocales.push(LOCALES.find((i) => i.locale === lang).language);
        } else if (
          toString(item[lang]?.body).length &&
          !/@SurveyLink/i.test(item[lang]?.body) &&
          !/@OnDemandLink/i.test(item[lang]?.body)
        ) {
          missingLocales.push(LOCALES.find((i) => i.locale === lang).language);
        }
      });
      if (!missingLocales.length) {
        return Promise.resolve();
      }
      return Promise.reject(
        `In ${item.name || ''} email body survey link is missing for ${missingLocales.join(', ')}`,
      );
    },
  };
}

export function localesValidator({ getFieldValue }, fieldName, locales, label) {
  return {
    validator(rule, value) {
      const componentsField = getFieldValue('components');
      if (locales.length === 1) {
        if (!value?.length || value?.trim().length) return Promise.resolve();
        return Promise.reject(`${label} is required`);
      }
      const missingLocales = [];
      locales.forEach((lang) => {
        if (!componentsField[lang][fieldName]) {
          missingLocales.push(LOCALES.find((item) => item.locale === lang).language);
        }
      });
      if (!missingLocales.length) {
        return Promise.resolve();
      }
      return Promise.reject(`Fill ${label.toLowerCase()} for ${missingLocales.join(', ')}`);
    },
  };
}

export function mapComponents(components, locales) {
  return locales.flatMap((lang) =>
    Object.entries(components[lang])
      .filter(([componentType, value]) => !isNil(value))
      .map(([componentType, value]) => ({
        componentType,
        locale: lang,
        value,
      })),
  );
}

export function mapComponentsFromApi(components, locales) {
  const result = {};
  locales.forEach((item) => {
    result[item] = {};
  });
  components.forEach((item) => {
    if (result[item.locale]) {
      result[item.locale][item.componentType] = item.value;
    }
  });
  return result;
}

export function mapSurveyFromApi(surveyInfo = {}) {
  const locales = (surveyInfo.locales || 'en').split(',');

  return {
    id: surveyInfo.id,
    surveyname: surveyInfo.surveyname,
    locales,
    isRunning: surveyInfo.lastSurveyInstance?.isRunning,
    autodetectLocales: surveyInfo.autodetectLocales,
    lastSurveyInstance: {
      id: surveyInfo.lastSurveyInstance?.id,
      endts: moment(surveyInfo.lastSurveyInstance?.endts),
      startts: moment(surveyInfo.lastSurveyInstance?.startts),
    },
    type: surveyInfo.type,
    linkToSurveyPreview: surveyInfo.linkToSurveyPreview,
    provideSuggestions: surveyInfo.provideSuggestions,
    limitMembersSelection: surveyInfo.limitMembersSelection,
    allowRanking: surveyInfo.allowRanking,
    enableSelfRegistration: surveyInfo.enableSelfRegistration,
    takeSurveySmsText: surveyInfo.takeSurveySmsText,
    whitelistedOrg: surveyInfo.whitelistedOrg,
    testMessageBody: surveyInfo.testMessageBody,
    testMessageSubject: surveyInfo.testMessageSubject,
    selfRegistrationPosition: surveyInfo.selfRegistrationPosition,
    groupId: surveyInfo.groupID,
    statementGroupDimensionId: surveyInfo.statementGroupDimensionId,
    deiTemplateType: surveyInfo.deiTemplateType,
    nominationsListType: surveyInfo.id === -1 ? 1 : surveyInfo.nominationsListType,
    nominationsDimensions: JSON.parse(surveyInfo.nominationsDimensions),
    threshold: surveyInfo.threshold,
    managerFeedbackDeadline: surveyInfo?.managerFeedbackDeadline
      ? moment(surveyInfo?.managerFeedbackDeadline)
      : null,
  };
}

export function mapSurvey(surveyInfo = {}) {
  return {
    id: surveyInfo.id,
    surveyname: surveyInfo.surveyname,
    locales: surveyInfo.locales.join(','),
    autodetectLocales: surveyInfo.autodetectLocales,
    lastSurveyInstance: {
      id: surveyInfo.lastSurveyInstance?.id,
      endts: moment(surveyInfo.lastSurveyInstance?.endts).format(DEFAULT_API_FORMAT),
      startts: moment(surveyInfo.lastSurveyInstance?.startts).format(DEFAULT_API_FORMAT),
    },
    type: surveyInfo.type,
    provideSuggestions: surveyInfo.provideSuggestions,
    limitMembersSelection: surveyInfo.limitMembersSelection,
    allowRanking: surveyInfo.allowRanking,
    enableSelfRegistration: surveyInfo.enableSelfRegistration,
    takeSurveySmsText: surveyInfo.takeSurveySmsText,
    whitelistedOrg: surveyInfo.whitelistedOrg,
    selfRegistrationPosition: surveyInfo.selfRegistrationPosition,
    statementGroupDimensionId: surveyInfo.statementGroupDimensionId,
    deiTemplateType: surveyInfo.deiTemplateType,
    nominationsListType: surveyInfo.nominationsListType,
    threshold: surveyInfo.threshold,
    nominationsDimensions: JSON.stringify(toArray(surveyInfo.nominationsDimensions)),
    managerFeedbackDeadline: surveyInfo.managerFeedbackDeadline
      ? moment(surveyInfo.managerFeedbackDeadline).format(DEFAULT_API_FORMAT)
      : null,
  };
}

export function mapEmailsFromApi(emails, locales) {
  const result = [];
  emails
    .sort((a, b) => a.typeId - b.typeId)
    .forEach((e, index) => {
      result.push({
        emailId: e.id,
        name: e.name,
        typeId: e.typeId,
      });
      locales.forEach((l) => {
        const item = e.emailDetails.find((i) => i.locale === l);
        if (item) {
          result[index][l] = {
            body: item.body,
            subject: item.subject,
            id: item.id,
          };
        } else {
          result[index][l] = {};
        }
      });
    });
  return result;
}

export function mapEmails(emails, locales) {
  const result = [];
  emails.forEach((e, index) => {
    result.push({
      id: e.emailId,
      name: e.name,
      typeId: e.typeId,
      emailDetails: [],
    });
    locales.forEach((l) => {
      result[index].emailDetails.push({
        locale: l,
        subject: e[l]?.subject,
        body: e[l]?.body,
        id: e[l]?.id,
      });
    });
  });
  return result;
}

export function hasConditionalQuestions(item) {
  return (
    item.type.id !== QUESTIONS_TYPES.SECTION &&
    (item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_dimensions')?.value
      .length > 0 ||
      item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_answers')?.value
        .length > 0)
  );
}

export function renderConditionalQuestionsTooltip(item, dimensions, value, allQuestions) {
  let resultStr = '';
  if (
    item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_dimensions')?.value
      .length > 0 &&
    dimensions?.length
  ) {
    const cdValue = JSON.parse(
      item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_dimensions')?.value,
    );
    const cdValueStr = Object.keys(cdValue).map((v) => {
      const dimension = dimensions.find((dimensionItem) => dimensionItem.dimension_code === v);
      return `<div><b>${dimension.display_name} (${cdValue[v].join(', ')})</b></div>`;
    });
    resultStr += `Display this question to respondents that belong to the following user dimensions:</br> ${cdValueStr.join(
      '',
    )}`;
  }
  if (
    item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_answers')?.value.length >
    0
  ) {
    const questions = cloneDeep(value);
    const caValue = JSON.parse(
      item?.questionMetadata?.find((qm) => qm.dimensionCode === 'conditional_answers')?.value,
    );
    const referQuestion = questions.find((q) => q.id === parseInt(Object.keys(caValue)[0]));
    let answers = [];
    if (referQuestion?.type?.id === QUESTIONS_TYPES.FIXED) {
      answers = QUESTIONS_ANSWERS;
    }
    if (referQuestion?.type?.id === QUESTIONS_TYPES.NPS) {
      answers = NSP_QUESTION_ANSWERS;
    }
    if (referQuestion?.type?.id === QUESTIONS_TYPES.MULTISELECT) {
      const question = allQuestions.find((q) => q.id === referQuestion.id);
      if (question) {
        let options;
        const optionsMetadata = question.questionMetadata.find(
          (metadata) => metadata.dimensionCode === 'options',
        );
        if (optionsMetadata !== undefined) {
          try {
            options = JSON.parse(optionsMetadata.value).map((i) => ({
              text: i,
              value: i,
            }));
          } catch (e) {
            options = [];
          }
        } else {
          options = [];
        }
        answers = options;
      }
    }
    const selectedAnsers = Object.values(caValue)[0]
      .map((v) => answers.find((a) => a.value === v))
      .filter((elem) => elem !== undefined);
    if (selectedAnsers.length > 0) {
      const selectedAnsersStr = selectedAnsers.map((a) => `<b>${a.text}</b>`).join(', ');
      if (resultStr.length > 0) {
        resultStr += '</br>';
      }
      resultStr += `Display this question to respondents if they selected  ${selectedAnsersStr} in question "${referQuestion.textval}".`;
    }
  }
  return resultStr;
}

export async function copyMessagesFromParentToChildSurveys(componentType, parenSurvey) {
  let messagesTypes = [];
  if (componentType === EMAIL_TYPES_NAME.ALL_MESSAGES) {
    messagesTypes = Object.values(EMAIL_TYPES_NAME).filter((value) => value !== 'all');
  } else {
    messagesTypes.push(componentType);
  }
  if (parenSurvey.id && messagesTypes.length > 0) {
    try {
      await post(`/surveys/${parenSurvey.id}/copy_components`, messagesTypes);
      const msg = `Your ${
        messagesTypes.length > 1 ? 'messages were' : 'message was'
      } successfully copied to child surveys.`;
      showSuccess(msg);
    } catch (error) {
      showError('Error:', error);
    }
  }
}

export function getUserFullName(user) {
  return (
    `${user.firstName ? user.firstName.trim() : ''} ${
      user.lastName ? user.lastName.trim() : ''
    }`.trim() || user.email
  );
}

export function getSurveysTree(list) {
  const res = [];
  list = toArray(list).reverse();
  toArray(list)
    .filter((item) => item.type === 'comm')
    .forEach((item) => {
      item.children = [];
    });
  res.push(...toArray(list).filter((item) => item.type !== '360'));
  toArray(list)
    .filter((item) => item.type === '360')
    .forEach((item) => {
      const parent = res.find((s) => s.id === item.parentID360);
      if (parent && parent.type === 'comm') {
        parent.children.push(item);
      } else {
        res.push(item);
      }
    });
  res.forEach((item) => {
    if (!toArray(item.children).length) {
      item.children = undefined;
    }
  });
  return res;
}

export function checkForInvalidEmails(emails) {
  const invalidEmails = validateEmails(emails);
  if (invalidEmails.length > 0) {
    const emailListString = invalidEmails.join(', ');
    const isPlural = invalidEmails.length > 1;
    const emailMessage = `The following email${
      isPlural ? 's are' : ' is'
    } invalid: ${emailListString}`;
    return emailMessage;
  }
}
