import { FormInstance } from 'antd';
import { RuleObject } from 'antd/es/form';
import { AxiosError } from 'axios';

import { USER_STORAGE } from '../constants';

import { showError } from './alerts';
import { get } from './backend';
import ss from './ss';

export enum UserRole {
  OrganizationAdmin = 'org_admin',
  SuperAdmin = 'super_admin',
  LimitedUser = 'limited_user',
  DiscussionMaterials = 'discussion_materials',
}

export interface UserUI {
  accessToken: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  userId: number | null;
  rememberMe: boolean | null;
  statisticsView: string | null;
  orgId: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userGroups: any[] | null;
  hasSurveys: boolean;
  intercomUserHash: string | null;
  roleCode: string | null;
  surveyTakingUrl: string | null;
  surveyTakingOrigin: string | null;
  deiSurveyTakingUrl: string | null;
  baseOrgName: string | null;
  organizationName: string | null;
  baseOrgId: number | null;
  navigationUserFullName: string | null;
  baseUserId: number | null;
  isSuperDuperAdmin: boolean;
  hasAccessToDeiSurveys: boolean;
  isSuperAdmin: boolean;
  isDiscussionMaterialRole: boolean;
  isAdmin: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewSettings: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orgDefaultViewSettings: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMappingSettings: any | null;
  hasManagerReviews: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createUserFromResponse = (resp: any): UserUI => {
  let orgDefaultViewSettings;
  let viewSettings;
  try {
    orgDefaultViewSettings = JSON.parse(resp.orgDefaultViewSettings);
  } catch (e) {
    orgDefaultViewSettings = {};
  }
  try {
    viewSettings = JSON.parse(resp.viewSettings);
  } catch (e) {
    viewSettings = {};
  }
  return {
    accessToken: resp.token,
    baseOrgId: resp.baseOrgId,
    baseOrgName: resp.baseOrgName,
    baseUserId: resp.baseUserId || resp.id,
    deiSurveyTakingUrl: resp.deiSurveyTakingUrl,
    email: resp.email,
    fieldMappingSettings: resp.fieldMappingSettings?.length
      ? JSON.parse(resp.fieldMappingSettings)
      : {},
    firstName: resp.first_name,
    fullName: resp.fullName,
    hasAccessToDeiSurveys: resp.hasAccessToDeiSurveys,
    hasManagerReviews: resp.hasManagerReviews,
    hasSurveys: resp.hasSurveys,
    intercomUserHash: resp.intercomUserHash,
    isAdmin: resp.roleCode === UserRole.SuperAdmin || resp.roleCode === UserRole.OrganizationAdmin,
    isDiscussionMaterialRole: resp.roleCode === UserRole.DiscussionMaterials,
    isSuperAdmin: resp.roleCode === UserRole.SuperAdmin,
    isSuperDuperAdmin: resp.isSuperAdmin,
    lastName: resp.last_name,
    navigationUserFullName: resp.navigationUserFullName,
    orgDefaultViewSettings,
    orgId: resp.organizationId,
    organizationName: resp.organizationName,
    rememberMe: resp.rememberMe,
    roleCode: resp.roleCode,
    statisticsView: resp.statisticsView,
    surveyTakingOrigin: resp.surveyTakingOrigin,
    surveyTakingUrl: resp.surveyTakingUrl,
    userGroups: resp.userGroups,
    userId: resp.id,
    viewSettings,
  };
};

export const removeUserInfo = () => ss.clear();

export const getUserInfo = (): Partial<UserUI> => ss.get<UserUI>(USER_STORAGE) ?? {};

export const getRedirectUrl = (user: UserUI, returnUrl?: string): string => {
  const toGettingStarted = !user.hasSurveys && user.isAdmin;

  if (user.isDiscussionMaterialRole) {
    if (user.hasManagerReviews) {
      return '/manager-reviews';
    }
    if (user.hasAccessToDeiSurveys) {
      return '/reports/dei-dashboard';
    }
    return '/reports/discussion-materials';
  }

  if (toGettingStarted) {
    return '/getting-started';
  }

  if (returnUrl) {
    return returnUrl;
  }

  return '/dashboard';
};

export const checkPasswordChangeDate = async () => {
  try {
    return await get('/users/check_password_change_date');
  } catch (error) {
    const axiosError = error as AxiosError;
    const errorMessage = axiosError.message || 'An unexpected error occurred';
    showError(errorMessage);
  }
};

export function validatePassword(form: FormInstance) {
  return async function (rule: RuleObject, newPassword: string) {
    const oldPassword = form.getFieldValue('oldPassword');

    if (!newPassword) {
      throw new Error('');
    }
    if (newPassword === oldPassword) {
      throw new Error('Password must be different than the previous one');
    }
    if (!/^\S*$/.test(newPassword)) {
      throw new Error('Password cannot contain spaces');
    }
    if (
      newPassword.length < 10 ||
      !/\d/.test(newPassword) ||
      !/[A-Z]/.test(newPassword) ||
      !/(?=\S*[\W_])\S*$/.test(newPassword)
    ) {
      throw new Error('The password does not fulfill the complexity criteria');
    }
  };
}
