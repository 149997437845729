import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

/**
 * Converts react component to string
 * -- NOTE --
 * You can spot overrides for console.error; it is needed to avoid warnings in the console.
 * Some of the components have handlers that don't work when rendering to a string.
 */
export const renderComponentToString = (node: ReactElement): string => {
  // eslint-disable-next-line no-console
  const originalConsoleError = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (/useLayoutEffect does nothing on the server/.test(args[0])) {
      return;
    }
    originalConsoleError(...args);
  };

  try {
    return ReactDOMServer.renderToString(node);
  } finally {
    // eslint-disable-next-line no-console
    console.error = originalConsoleError;
  }
};

export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function retryRequestsOnNetworkError<T>(
  getPromises: () => Promise<T>[],
  isCancelled: () => boolean,
  timeout = 10000,
  retriesCount = 1000,
): Promise<T[]> {
  if (isCancelled()) throw new Error('Cancelled');
  const promisesRes = await Promise.allSettled(getPromises());
  if (isCancelled()) throw new Error('Cancelled');

  const hasNetworkError = promisesRes.some(
    (result) => result.status === 'rejected' && result.reason?.code === 504,
  );
  const hasNonNetworkError = promisesRes.some(
    (result) => result.status === 'rejected' && result.reason?.code !== 504,
  );

  if (hasNonNetworkError) {
    throw new Error('Non network error');
  } else if (hasNetworkError && retriesCount > 0) {
    await wait(timeout);
    if (isCancelled()) throw new Error('Cancelled');
    const res = await retryRequestsOnNetworkError(
      getPromises,
      isCancelled,
      timeout,
      retriesCount - 1,
    );
    if (isCancelled()) throw new Error('Cancelled');
    return res;
  } else if (hasNetworkError) {
    throw new Error('Network error');
  } else {
    return promisesRes.map((x) => (x as PromiseFulfilledResult<T>).value);
  }
}

export const saveParseJson = <T,>(json: string | null, defaultResult: T): T => {
  if (!json) return defaultResult;
  try {
    return JSON.parse(json);
  } catch (e) {
    return defaultResult;
  }
};
