import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../../store/user';
import { getRedirectUrl } from '../../utils/auth';
import Loader from '../Loader';

const RootRedirectRoute = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user } = useContext(UserContext) as any;
  return !user.userId ? <Loader /> : <Redirect to={getRedirectUrl(user)} />;
};

export default RootRedirectRoute;
