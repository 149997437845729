import React from 'react';
import { findIndex, isUndefined, mergeWith, omitBy, unionBy } from 'lodash';
import moment, { isMoment } from 'moment';

import { THEME_URL } from '../config';

export function cloneDeep(variable) {
  if (variable === null) return null;

  if (moment.isMoment(variable)) {
    return variable;
  }

  if (typeof variable === 'object') {
    if (variable instanceof Array) {
      const cloneArray = [];
      for (let i = 0; i < variable.length; i++) {
        cloneArray.push(cloneDeep(variable[i]));
      }
      return cloneArray;
    }
    const cloneObject = {};
    Object.keys(variable).forEach((field) => {
      cloneObject[field] = cloneDeep(variable[field]);
    });
    return cloneObject;
  }
  return variable;
}

export function arrayMove(input, from, to) {
  const array = cloneDeep(input);
  const insertIndex = to > from ? to + 1 : to;
  array.splice(insertIndex, 0, array[from]);
  const deleteIndex = to > from ? from : from + 1;
  array.splice(deleteIndex, 1);
  return array;
}

export function formatDateOrRelativeWithinWeek(date) {
  const today = moment().startOf('day');
  const d = moment(date).startOf('day');
  const difference = d.diff(today, 'days');

  return difference === 0
    ? 'today'
    : Math.abs(difference) <= 7
    ? today.to(d)
    : d.format('MM/DD/YYYY');
}

export function formatDate(date, frequency) {
  if (!date || !frequency) {
    return undefined;
  }
  date = moment(date, 'MM/DD/YYYY');
  if (frequency === 'monthly') {
    return moment(date.startOf('month')).format('MMMM YYYY');
  }
  if (frequency === 'monthly_short') {
    return date.format('MMM YY');
  }
  if (frequency === 'american') {
    return date.format('MM/DD/YYYY');
  }
  return date;
}

export function isObject(value) {
  return typeof value === 'object' && value !== null && !(value instanceof Array);
}

export function toObject(val) {
  return isObject(val) ? val : {};
}

export function toArray(value) {
  if (value && isObject(value)) {
    return Object.keys(value).map((key) => value[key]);
  }
  return Array.isArray(value) ? value : [];
}

export function toFloat(val) {
  if (Number.isNaN(val)) {
    return 0;
  }
  const type = typeof val;
  switch (true) {
    case type === 'number':
      return val;
    case type === 'boolean':
      return val ? 1 : 0;
    case type === 'string':
      return parseFloat(val) || 0;
    default:
      return 0;
  }
}

export function toInt(val) {
  return Math.round(toFloat(val));
}

export function toBoolean(value) {
  if (value === null || value === undefined) {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'object') {
    return true;
  }
  if (typeof value === 'string' && ['yes', 'true', '1'].indexOf(value.toLowerCase()) >= 0) {
    return true;
  }
  value = toFloat(value);
  return value !== 0;
}

function emptyStringToUndefined(a) {
  return a === '' ? undefined : a;
}

function falseToUndefined(a) {
  return a === false ? undefined : a;
}

function nullToUndefined(a) {
  return a === null ? undefined : a;
}

export function toString(val) {
  const type = typeof val;
  switch (true) {
    case type === 'number':
      return val.toString();
    case type === 'boolean':
      return val ? '1' : '0';
    case type === 'string':
      return val;
    default:
      return '';
  }
}

export function deepEquals(a, b, strict = true, params = {}) {
  if (typeof a === 'object' && a !== null) {
    if (typeof b !== 'object') {
      return false;
    }
    if (Array.isArray(a)) {
      if (!Array.isArray(b)) {
        return false;
      }
    }

    if (
      (moment.isMoment(a) && !moment.isMoment(b)) ||
      (moment.isMoment(b) && !moment.isMoment(a))
    ) {
      return false;
    }
    if (moment.isMoment(a) && moment.isMoment(b)) {
      return a.valueOf() === b.valueOf();
    }

    if ((strict || Array.isArray(a)) && Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }
    const keysA = Object.keys(a);
    for (let i = 0; i < keysA.length; i++) {
      const key = keysA[i];
      // eslint-disable-next-line no-prototype-builtins
      if (strict && !b.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEquals(a[key], b[key], strict, params)) {
        return false;
      }
    }
    const keysB = Object.keys(b);
    for (let i = 0; i < keysB.length; i++) {
      const key = keysB[i];
      // eslint-disable-next-line no-prototype-builtins
      if (strict && !b.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEquals(a[key], b[key], strict, params)) {
        return false;
      }
    }
    return true;
  }
  if (params.emptyStringToUndefined) {
    a = emptyStringToUndefined(a);
    b = emptyStringToUndefined(b);
  }
  if (params.falseToUndefined) {
    a = falseToUndefined(a);
    b = falseToUndefined(b);
  }
  if (params.nullToUndefined) {
    a = nullToUndefined(a);
    b = nullToUndefined(b);
  }
  return a === b;
}

export function showTableTotal(total, range) {
  return `${range[0]}-${range[1]} of ${total} items`;
}

export function getMessage(text) {
  return (
    <span>
      <b>Don&apos;t close your page</b>
      <div>{text}</div>
    </span>
  );
}

export function mergeDeep(object, ...sources) {
  return mergeWith(object, ...sources, (objValue, srcValue) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
    if (moment.isMoment(srcValue)) {
      return srcValue;
    }
  });
}

export function isSet(value) {
  return value !== null && value !== undefined;
}

export function isEmptyObject(value) {
  const obj = toObject(value);
  return Object.keys(obj).length === 0;
}

export function capitalize(string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachWord(string) {
  if (!string) return '';
  const words = string.trim().split(' ');
  return words
    .map((word) => {
      if (word.length === 0) return '';
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmails(emailArray) {
  return emailArray
    .filter((email) => String(email).trim())
    .filter((email) => !emailRegex.test(String(email)));
}

export function isFloat(value) {
  const regex = /-?\d+\.\d+/;
  return regex.test(value) && value - Math.floor(value) > 0;
}

export function isInt(value) {
  const regex = /-?\d+\.\d+/;
  return regex.test(value) && value - Math.floor(value) === 0;
}

export function numberRange(start, end) {
  return new Array(end - start).fill().map((d, i) => i + start);
}

export function toMoney(value) {
  return toString(value).length > 0 && toString(value) !== '-'
    ? `$${toString(Math.round(value * 100) / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : toString(value);
}

export function toNumber(value) {
  return toString(value).length > 0 && toString(value) !== '-'
    ? toString(Math.round(toFloat(value) * 100) / 100)
    : toString(value);
}

export function toPercent(value) {
  if (toString(value).length < 1 || toString(value) === '-') {
    return toString(value);
  }

  if (value.toFixed) {
    return `${value.toFixed(2)}%`;
  }
  return `${toString(Math.round(value * 100) / 100)}%`;
}

export function emptyRender(value) {
  return toString(value);
}

export function importThemeModule(moduleName = '') {
  if (!moduleName) {
    return Promise.reject();
  }
  const moduleUrl = `${THEME_URL}/${moduleName}?${Date.now()}`;
  return import(/* webpackIgnore: true */ moduleUrl);
}

export function uniqIntId() {
  const now = new Date();
  const dateStr = now.toISOString().substring(0, 10).replace(/-/g, '');
  const timeStr = now.toTimeString().substring(0, 8).replace(/:/g, '');
  const randomInt = Math.floor(Math.random() * 1000000);
  return parseInt(`${dateStr}${timeStr}${randomInt}`, 10);
}

export function cleanDataFromUndefined(objects) {
  return Object.fromEntries(
    Object.entries(objects).map(([key, value]) => [key, omitBy(value, isUndefined)]),
  );
}

export function addOrReplace(array, newItem, key) {
  const index = findIndex(array, [key, newItem[key]]);
  if (index > -1) {
    return unionBy(array, [newItem], key);
  }
  return [...array, newItem];
}
