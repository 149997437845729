export function Color(_r, _g, _b) {
  const r = _r;
  const g = _g;
  const b = _b;

  this.getColors = () => {
    const colors = {
      r,
      g,
      b,
    };
    return colors;
  };
}

const defaultColors = {
  green: new Color(152, 196, 73),
  lightRed: new Color(251, 245, 246),
  red: new Color(167, 52, 55),
  lightGreen: new Color(240, 246, 229),
};

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export default function getHeatmapColor(
  val,
  min = 0,
  max = 100,
  colors = defaultColors,
  returnHex = false,
) {
  function Interpolate(start, end, steps, count) {
    const final = start + ((end - start) / steps) * count;
    return Math.floor(final);
  }

  const { green, lightRed, red, lightGreen } = colors;
  let start = lightRed;
  let end = red;
  let diff = max - min;
  diff = diff || 2;
  const center = (max + min) / 2;

  if (val >= center) {
    start = lightGreen;
    end = green;
  }
  const startColors = start.getColors();
  const endColors = end.getColors();
  const value = Math.abs(center - val);
  const r = Interpolate(startColors.r, endColors.r, diff / 2, value);
  const g = Interpolate(startColors.g, endColors.g, diff / 2, value);
  const b = Interpolate(startColors.b, endColors.b, diff / 2, value);
  if (returnHex) {
    return rgbToHex(r, g, b);
  }
  return `rgb(${r},${g},${b})`;
}
