/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Tabs,
  theme,
} from 'antd';
import { omit, pick, some } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { LOCALES } from '../../../config';
import { CONTACT_METHODS, STATUSES, UNSAVED_DATA } from '../../../constants';
import { CommonContext } from '../../../store/common';
import { UserContext } from '../../../store/user';
import { showError } from '../../../utils/alerts';
import { validatePassword } from '../../../utils/auth';
import { get, post, put } from '../../../utils/backend';
import { cloneDeep, deepEquals, toArray } from '../../../utils/helpers';
import { validateMessages } from '../../../utils/validation';
import Loader from '../../Loader';
import MomentDatePicker from '../../MomentDatePicker';
import SendAccessReportWithBackBtn from '../SendAccessReportWithBackBtn';

import History from './History';
import ReportAccess from './ReportAccess';

import './styles.scss';

const { Option } = Select;
const { useToken } = theme;
const DM_ROLE_ID = 9;
const ORG_ADMIN_ROLE_ID = 3;
const LIMITED_USER_ROLE_ID = 5;
const ACCESS_TAB_ERROR_MSG = 'Report Access Error';

const User = React.memo(
  ({ visible, onCancel, onSave, id, surveyId, setVisible, modifyingUser, onHide }) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [roles, setRoles] = useState([]);
    const [rolesLoading, setRolesLoading] = useState(true);
    const [dimensions, setDimensions] = useState([]);
    const [dimensionsLoading, setDimensionsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [activeTab, setActiveTab] = useState('1');
    const [accessSurveys, setAccessSurveys] = useState([]);
    const [accessSurveysOriginal, setAccessSurveysOriginal] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [surveysLoading, setSurveysLoading] = useState(false);
    const [reportAccessLoading, setReportAccessLoading] = useState(false);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    const [accessOrgsLoading, setAccessOrgsLoading] = useState(false);
    const [activityLogs, setActivityLogs] = useState([]);
    const { user: currentUser } = useContext(UserContext);
    const { organizations } = useContext(CommonContext);
    const [showRoleAlert, setShowRoleAlert] = useState(false);
    const [runAccessTabValidation, setRunAccessTabValidation] = useState(false);
    const [sendCredentials, setSendCredentials] = useState(false);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [saveReportAccessTab, setSaveReportAccessTab] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const { token } = useToken();

    const { isSuperAdmin, isAdmin } = currentUser;
    const showReportAccess = !surveyId;
    const [passwordRulesState, setPasswordRulesState] = useState({
      minLength: false,
      oneNumber: false,
      oneUpper: false,
      oneSpecialChar: false,
    });

    const updatePasswordRulesState = (value) => {
      setPasswordRulesState({
        minLength: value.length >= 10,
        oneNumber: /\d/.test(value),
        oneUpper: /[A-Z]/.test(value),
        oneSpecialChar: /(?=\S*[\W_])\S*$/.test(value),
      });
    };

    function generatePassword(length = 10) {
      const chars = 'abcdefghijklmnopqrstuvwxyz0123456789@#$%^&*';
      let password = '';
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    }

    function saveUser(values) {
      setSaving(true);
      const body = cloneDeep({ ...values, lastName: values.lastName || '' });
      const role = roles.find((item) => item.id === values.role) || {};
      body.role = {
        id: values.role,
        code: role.code,
      };
      if (surveyId) {
        values.userDimensions = values.userDimensions || {};
        body.userDimensions = dimensions.map((item) => {
          const res = cloneDeep(item);
          res.value = values.userDimensions[item.dimension_code] || '';
          if (item.type === 'date' && res.value !== '') {
            res.value = moment(res.value).format('YYYY-MM-DD');
          }
          return res;
        });
        body.surveyId = surveyId;
      }
      body.newPassword = values.newPassword || '';
      delete body.password;
      if (id) {
        delete body.newPassword;
        body.userId = parseInt(id, 10);
        body.id = user.id;
      }
      const method = id ? put : post;
      const promices = [method('/users', body)];
      if (id && isSuperAdmin) {
        promices.push(post(`/consultant/${user.userId}`, selectedOrgs));
      }
      Promise.all(promices)
        .then((res) => {
          if (!id) {
            setUser((prevValue) => ({ ...prevValue, userId: res[0] }));
          }
          setSaving(false);
          setAccessSurveys([]);
          if (sendCredentials) {
            onHide();
          } else {
            onSave();
          }
        })
        .catch((e) => {
          showError(e.message);
          setSaving(false);
        });
    }

    async function validateForm() {
      const values = await form.validateFields();
      const isAccessInvalidValid = some(
        accessSurveys,
        (accessUser) => accessUser.fullReportAccess === false && !accessUser.dimensionList.length,
      );
      if (isAccessInvalidValid) {
        setRunAccessTabValidation(true);
        throw new Error(ACCESS_TAB_ERROR_MSG);
      }
      setRunAccessTabValidation(false);
      return values;
    }

    async function saveDetailTab(values) {
      const excludedProps = ['id', 'userDimensions', 'userId', 'accessSurveys'];
      if (!deepEquals(values, omit(user, excludedProps))) {
        await saveUser({
          ...values,
          pass: values.role !== LIMITED_USER_ROLE_ID ? values.newPassword : generatePassword(),
        });
        setWasSaved(true);
      }
    }

    async function onSubmit() {
      setSaving(true);
      try {
        const values = await validateForm();
        await saveDetailTab(values);
        if (!deepEquals(accessSurveys, accessSurveysOriginal)) {
          setWasSaved(true);
          setSaveReportAccessTab(true);
        }
        setSaving(false);
        setVisible(false);
      } catch (e) {
        if (e.message === ACCESS_TAB_ERROR_MSG) {
          setActiveTab('2');
        } else {
          setActiveTab('1');
          form.scrollToField(e.errorFields[0].name, { block: 'end' });
        }
        setSaving(false);
        setWasSaved(false);
      }
    }

    function onClose() {
      const current = {
        ...form.getFieldsValue(),
      };
      const oldUser = cloneDeep(user);
      delete oldUser.id;
      delete oldUser.userId;
      const showConfirm = !deepEquals(current, oldUser, false, {
        falseToUndefined: true,
        emptyStringToUndefined: true,
        nullToUndefined: true,
      });
      if (!showConfirm) {
        onCancel();
        setSendCredentials(false);
        setAccessSurveys([]);
        return;
      }
      Modal.confirm({
        title: UNSAVED_DATA,
        onOk() {
          setSendCredentials(false);
          setAccessSurveys([]);
          onCancel();
        },
      });
    }

    function onOkHandle() {
      onSave();
      message.success('Changes were saved');
      setSendCredentials(false);
      setShowCredentialsModal(false);
      setAccessSurveys([]);
    }

    function onBackHandle() {
      setActiveTab('2');
      setVisible(true);
    }

    function onTabChange(activeKey) {
      setActiveTab(activeKey);
    }

    function renderValidationIcon(isValid) {
      return isValid ? (
        <CheckOutlined className="text-xs pr-4" />
      ) : (
        <CloseOutlined className="text-xs pr-4" />
      );
    }

    function onRoleChange(value) {
      if (user.role === DM_ROLE_ID && ![ORG_ADMIN_ROLE_ID, DM_ROLE_ID].includes(value)) {
        setShowRoleAlert(true);
      } else {
        setShowRoleAlert(false);
      }
      if (!id && value !== (roles.find((item) => item.code === 'limited_user') || {}).id) {
        setShowPassword(true);
      } else {
        setShowPassword(false);
      }
    }

    function renderField(type, options) {
      if (options.length) {
        switch (type) {
          case 'dropdown':
            return (
              <Select>
                {options.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            );

          case 'radio_button':
            return (
              <Radio.Group>
                {options.map((item) => (
                  <Radio value={item.name} key={item.id}>
                    {item.name}
                  </Radio>
                ))}
              </Radio.Group>
            );
          default:
            return <Input type="text" />;
        }
      }

      switch (type) {
        case 'date':
          return <MomentDatePicker />;

        case 'number':
          return <InputNumber />;

        case 'email':
          return <Input type="email" />;

        case 'password':
          return <Input.Password />;

        case 'string':
        case 'phone':
          return <Input type="text" />;

        default:
          return <Input type="text" />;
      }
    }

    useEffect(() => {
      const saveReportAccess = async () => {
        if (!saveReportAccessTab || !accessSurveys || !user?.userId) return;
        try {
          await post(`/users/${user.userId}/access`, accessSurveys);
        } catch (e) {
          showError('Failed to save report access');
        } finally {
          setSaveReportAccessTab(false);
        }
      };
      saveReportAccess();
    }, [accessSurveys, saveReportAccessTab, user?.userId]);

    useEffect(() => {
      if (!id) {
        if (roles.length) {
          const userInfo = {
            contactMethod: CONTACT_METHODS[0].value,
            status: STATUSES[0].value,
            locale: 'en',
            role: roles[0].id,
          };
          form.setFieldsValue(userInfo);
          setUser({ ...userInfo, accessSurveys, userDimensions: {} });
        }
      }
    }, [visible, roles]);

    useEffect(() => {
      if (!visible) {
        setUser({});
        setDimensions([]);
        setDimensionsLoading(true);
        setSaving(false);
        setActiveTab('1');
        setAccessOrgsLoading(false);
        setSelectedOrgs([]);
        setActivityLogs([]);
        setActiveTab('1');
        setShowPassword(false);
        return;
      }
      if (!roles.length) {
        get('/roles')
          .then((list) => {
            if (Array.isArray(list)) {
              setRoles(list);
            }
          })
          .catch((e) => showError(e.message))
          .finally(() => setRolesLoading(false));
      }
      get('/users/user_form_dimensions')
        .then((list) => {
          if (Array.isArray(list)) {
            setDimensions(list);
          }
        })
        .catch((e) => showError(e.message))
        .finally(() => setDimensionsLoading(false));
    }, [visible]);

    useEffect(async () => {
      if (!id || dimensionsLoading || rolesLoading) {
        return;
      }
      if (id) {
        setLoading(true);
        setSurveysLoading(true);
        try {
          const surveysResponse = await get(`/users/${id}/access`);
          if (surveysResponse?.length) {
            const reportAccesses = surveysResponse.map((survey) =>
              pick(survey, ['surveyId', 'fullReportAccess', 'dimensionList']),
            );
            setAccessSurveys(reportAccesses);
            setAccessSurveysOriginal(cloneDeep(reportAccesses));
          } else {
            setAccessSurveys([]);
            setAccessSurveysOriginal([]);
          }
          setLoading(false);
          setSurveysLoading(false);
        } catch (e) {
          showError(e.message);
          setSurveysLoading(false);
          setLoading(false);
        }
      }
      get(`/users/${id}/${surveyId}`)
        .then((data) => {
          const userInfo = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            role: (data.role || {}).id,
            contactMethod: data.contactMethod,
            status: data.status,
            locale: data.locale,
            kioskCode: data.kioskCode,
            alternative_email: data.alternative_email,
            userDimensions: {},
          };
          if (surveyId) {
            (data.userDimensions || []).forEach((item) => {
              userInfo.userDimensions[item.dimension_code] = item.value;
              if (item.type === 'date') {
                if (item.value === '') {
                  userInfo.userDimensions[item.dimension_code] = undefined;
                } else {
                  userInfo.userDimensions[item.dimension_code] = moment(item.value);
                }
              }
            });
          }
          setUser((prevUser) => ({
            ...userInfo,
            id: data.id,
            userId: parseInt(id, 10),
            accessSurveys: prevUser.accessSurveys || [],
          }));
          setActivityLogs(data.activityLogs.map((item, index) => ({ ...item, key: index })));
          form.setFieldsValue(userInfo);
        })
        .catch((e) => showError(e.message))
        .finally(() => setLoading(false));
      if (isSuperAdmin) {
        setAccessOrgsLoading(true);
        get(`/consultant/${id}`)
          .then((list) => {
            if (toArray(list).length) {
              setSelectedOrgs(list.map((item) => item.id));
            } else {
              setSelectedOrgs([]);
            }
          })
          .catch((e) => showError(e.message))
          .finally(() => setAccessOrgsLoading(false));
      }
    }, [id, rolesLoading, dimensionsLoading]);

    const showLoader =
      dimensionsLoading || rolesLoading || loading || surveysLoading || reportAccessLoading;

    const userForm = (
      <Form
        form={form}
        name="user-form"
        validateMessages={validateMessages}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
          <Input placeholder="Email" type="email" />
        </Form.Item>
        {showRoleAlert && (
          <Form.Item label=" ">
            <Alert
              message="Changing the role to Limited User will revoke any access to reports"
              style={{ marginTop: 10 }}
              onClose={() => setShowRoleAlert(false)}
              closable
            />
          </Form.Item>
        )}
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true }]}
          hidden={user?.status === 5}
        >
          <Select placeholder="Select role" loading={rolesLoading} onChange={onRoleChange}>
            {roles.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {showPassword && !id && (
          <>
            <Form.Item
              name="newPassword"
              label="Password"
              rules={[{ required: true }, { validator: validatePassword(form) }]}
              validateTrigger="onBlur"
            >
              <Input.Password
                placeholder="Password"
                onChange={(e) => updatePasswordRulesState(e.target.value)}
              />
            </Form.Item>
            <div className="password-feedback pb-12">
              <div className={passwordRulesState.minLength ? 'valid' : 'invalid'}>
                {renderValidationIcon(passwordRulesState.minLength)}
                10 characters minimum
              </div>
              <div className={passwordRulesState.oneNumber ? 'valid' : 'invalid'}>
                {renderValidationIcon(passwordRulesState.oneNumber)}
                At least one number (0-9)
              </div>
              <div className={passwordRulesState.oneUpper ? 'valid' : 'invalid'}>
                {renderValidationIcon(passwordRulesState.oneUpper)}
                At least one uppercase character (A-Z)
              </div>
              <div className={passwordRulesState.oneSpecialChar ? 'valid' : 'invalid'}>
                {renderValidationIcon(passwordRulesState.oneSpecialChar)}
                At least one special character (!@#$%&*)
              </div>
            </div>
            <Form.Item
              name="password"
              label="Confirm Password"
              validateTrigger="onBlur"
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
          </>
        )}
        <Form.Item name="contactMethod" label="Contact Method" rules={[{ required: true }]}>
          <Select placeholder="Select Contact Method" options={CONTACT_METHODS} />
        </Form.Item>
        <Form.Item
          hidden={user?.status === 5}
          name="status"
          label="Status"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select Status" options={STATUSES} />
        </Form.Item>
        <Form.Item name="locale" label="Locale" rules={[{ required: true }]}>
          <Select placeholder="Select locale" showSearch optionFilterProp="children">
            {LOCALES.map((item) => (
              <Option value={item.locale} key={item.locale}>
                {item.language}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="kioskCode" label="Kiosk Code">
          <Input placeholder="Kiosk Code" />
        </Form.Item>
        <Form.Item name="alternative_email" label="Alternative Email" rules={[{ type: 'email' }]}>
          <Input placeholder="Email" />
        </Form.Item>
        {!!surveyId &&
          dimensions.map((item) => (
            <Form.Item
              name={['userDimensions', item.dimension_code]}
              key={item.id}
              label={item.display_name}
            >
              {renderField(item.type, item.optionList ?? [])}
            </Form.Item>
          ))}
      </Form>
    );

    const tabPans = [
      {
        label: 'Details',
        key: '1',
        children: userForm,
      },
      ...(showReportAccess
        ? [
            {
              label: 'Report Access',
              key: '2',
              disabled: !id,
              children: (
                <>
                  <ReportAccess
                    surveys={surveys}
                    selectedSurveys={accessSurveys}
                    setSelectedSurveys={setAccessSurveys}
                    dimensions={dimensions}
                    runValidation={runAccessTabValidation}
                    setReportAccessLoading={setReportAccessLoading}
                  />
                  {!modifyingUser.credentialsSent && (
                    <>
                      <Divider />
                      <Row>
                        <Col span={24}>
                          <span>Send Credentials (optional)</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Switch
                            checked={sendCredentials}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(checked) => setSendCredentials(checked)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <span>Send credentials automatically along with the report access</span>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ),
            },
          ]
        : []),
      ...(isSuperAdmin
        ? [
            {
              label: 'Consultant Access',
              key: '3',
              children: (
                <Row gutter={[8, 0]} align="middle" className="no-wrap">
                  <Col>Organizations</Col>
                  <Col flex={1}>
                    <Select
                      className="w-100"
                      placeholder="Select organizations"
                      loading={accessOrgsLoading}
                      value={selectedOrgs}
                      onChange={setSelectedOrgs}
                      mode="multiple"
                      optionFilterProp="children"
                      virtual={false}
                    >
                      {organizations
                        .filter((item) => item.demo !== 2)
                        .map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.orgname}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
              ),
            },
          ]
        : []),
      ...(isAdmin
        ? [
            {
              label: 'History',
              key: '4',
              children: <History items={activityLogs} />,
            },
          ]
        : []),
    ];
    return (
      <>
        <Modal
          title={id ? 'Edit user' : 'Add new user'}
          className="user-modal"
          open={visible}
          onOk={() => onSubmit()}
          okText="Save"
          onCancel={onClose}
          okButtonProps={{ loading: saving }}
          width={token.screenMDMax}
          afterClose={() => {
            form.resetFields();
            setShowRoleAlert(false);
            setPasswordRulesState({
              minLength: false,
              oneNumber: false,
              oneUpper: false,
              oneSpecialChar: false,
            });
            if (sendCredentials) {
              onHide();
              setShowCredentialsModal(true);
              return;
            }
            if (wasSaved) {
              onSave();
              message.success('Changes were saved');
              setWasSaved(false);
            }
          }}
        >
          {showLoader && <Loader />}
          {id ? (
            <Tabs activeKey={activeTab} onChange={onTabChange} items={tabPans} tabPosition="top" />
          ) : (
            userForm
          )}
        </Modal>
        <SendAccessReportWithBackBtn
          visible={showCredentialsModal}
          onBack={() => onBackHandle()}
          onOk={() => onOkHandle()}
          selectedUsers={[id]}
          groups={Object.keys(modifyingUser).length ? [modifyingUser] : []}
          setVisible={setShowCredentialsModal}
        />
      </>
    );
  },
);

User.defaultProps = {
  visible: false,
  onCancel: () => {},
  onSave: () => {},
  id: undefined,
  surveyId: 0,
  setVisible: () => {},
  modifyingUser: {},
  onHide: () => {},
};

User.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  surveyId: PropTypes.number,
  setVisible: PropTypes.func,
  modifyingUser: PropTypes.object,
  onHide: PropTypes.func,
};

export default User;
