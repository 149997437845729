import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CommonContext } from '../../store/common';
import { UserContext } from '../../store/user';
import { getUserInfo } from '../../utils/auth';
import Page from '../Page';

const PrivateRoute = React.memo(
  ({ children, isAdmin, isSuperAdmin, isDiscussionMaterialRole, allowedRoles, ...rest }) => {
    const { user } = useContext(UserContext);
    const { setReturnUrl } = useContext(CommonContext);
    const { pathname } = useLocation();

    function isAuthenticated() {
      return user.userId || getUserInfo().userId;
    }

    function isAllowed() {
      if (allowedRoles.length && allowedRoles.any((role) => user[role] || getUserInfo()[role])) {
        return true;
      }
      if (isAdmin) {
        return user.isAdmin || getUserInfo().isAdmin;
      }
      if (isSuperAdmin) {
        return user.isSuperAdmin || getUserInfo().isSuperAdmin;
      }
      if (isDiscussionMaterialRole) {
        return user.isDiscussionMaterialRole || getUserInfo().isDiscussionMaterialRole;
      }
    }

    let redirectTo;
    if (isAdmin && isAuthenticated()) {
      redirectTo = isAllowed() ? undefined : '/not-allowed';
    } else if (!isAuthenticated()) {
      setReturnUrl(pathname);
      redirectTo = '/login';
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          !redirectTo ? (
            <Page>{children}</Page>
          ) : (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  },
);

PrivateRoute.defaultProps = {
  children: undefined,
  isAdmin: false,
  isSuperAdmin: false,
  isDiscussionMaterialRole: false,
  allowedRoles: [],
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isDiscussionMaterialRole: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRoute;
