import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

import { UserContext } from '../../store/user';

const NotFound = () => {
  const { user } = useContext(UserContext);
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary">
          <Link to="/login">Back Login</Link>
        </Button>
      }
    />
  );
};

export default NotFound;
