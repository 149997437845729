import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page from '../Page';

const RouteWithLayout = React.memo(({ children, pageClassName, hideNavbar, title, ...rest }) => (
  <Route {...rest}>
    <Page className={pageClassName} hideNavbar={hideNavbar} title={title}>
      {children}
    </Page>
  </Route>
));

RouteWithLayout.defaultProps = {
  children: undefined,
  pageClassName: undefined,
  hideNavbar: false,
  title: undefined,
};

RouteWithLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  pageClassName: PropTypes.string,
  hideNavbar: PropTypes.bool,
  title: PropTypes.string,
};

export default RouteWithLayout;
