/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Select } from 'antd';
import { sanitize } from 'dompurify';
import moment from 'moment';
import PropTypes from 'prop-types';

import { LOCALES } from '../../../config';
import { DEFAULT_UI_DATE_FORMAT, EMAIL_TYPES, SPECIALS_LIST } from '../../../constants';
import { UserContext } from '../../../store/user';
import { showError, showSuccess } from '../../../utils/alerts';
import { get, post, put } from '../../../utils/backend';
import { cloneDeep, toArray, toObject } from '../../../utils/helpers';
import {
  checkForInvalidEmails,
  localesEmailValidator,
  localesEmailWarning,
  mapComponentsFromApi,
  mapEmails,
  mapEmailsFromApi,
  mapSurveyFromApi,
} from '../../../utils/survey';
import { validateMessages } from '../../../utils/validation';
import Loader from '../../Loader';
import TextEditor from '../../TextEditor';
import LaunchAndSend from '../LaunchAndSend';
import SeedModal from '../SeedQuestions';
import SendPreview from '../SendPreview';

const specialsForEmail = [
  SPECIALS_LIST.USER_NAME,
  SPECIALS_LIST.SURVEY_LINK,
  SPECIALS_LIST.ENTROMY_LOGIN,
  SPECIALS_LIST.ENTROMY_URL,
  SPECIALS_LIST.SURVEY_END_DATE,
];

const { Option } = Select;

const Preview = React.memo(({ visible, onCancel, surveyId, isReminder, instanceId }) => {
  const [instance, setInstance] = useState({});
  const [loading, setLoading] = useState(true);
  const [surveySaving, setSurveySaving] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [components, setComponents] = useState({ en: {} });
  const [surveyEmails, setSurveyEmails] = useState([]);
  const [currentEmailId, setCurrentEmailId] = useState();
  const [emailPreviews, setEmailPreviews] = useState([]);
  const [currentPreviews, setCurrentPreviews] = useState({ en: {} });
  const [locales, setLocales] = useState([]);
  const [locale, setLocale] = useState('en');
  const [sendingPreviews, setSendingPreviews] = useState(false);
  const [sendingEmails, setSendingEmails] = useState(false);
  const [showSeedModal, setShowSeedModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showInformModal, setShowInformModal] = useState(false);
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const { isSuperAdmin } = user;

  function getTitle() {
    const surveyname = instance.survey?.surveyname;
    if (isReminder && surveyname) {
      return `Send reminder to all those who have not yet responded for ${surveyname}`;
    }
    if (isReminder) {
      return 'Send reminder';
    }
    if (surveyname) {
      return `Send survey for ${surveyname}`;
    }
    return 'Send survey';
  }

  function onLaunchAndSend(emails, subject, body) {
    form.validateFields().then(() => {
      const successPromises = [];
      successPromises.push(get(`/instances/${instanceId}/send_emails/0?emailId=${currentEmailId}`));
      successPromises.push(
        post('/instances/send_preview_email?isNotPreview=true', [
          {
            locale: 'en',
            subject,
            body,
            user: emails.map((email) => ({ email: email.trim() })),
          },
        ]),
      );
      Promise.all(successPromises).then(() => {
        showSuccess('Survey emails have been sent.');
        onCancel();
      });
    });
  }

  function sendPreviews(userEmails) {
    const emailsList = (userEmails ?? []).map((item) => item.trim());
    const validationMessage = checkForInvalidEmails(emailsList);
    if (validationMessage) {
      showError(validationMessage);
      return;
    }
    const emails = locales
      .filter((l) => currentPreviews[l.locale] && currentPreviews[l.locale].body)
      .map((l) => ({
        locale: l.locale,
        subject: currentPreviews[l.locale].subject,
        body: currentPreviews[l.locale].body,
        user: emailsList.length ? emailsList.map((item) => ({ email: item })) : undefined,
      }));
    if (!emails.length) {
      return;
    }
    setSendingPreviews(true);
    post('/instances/send_preview_email', emails)
      .then(() => showSuccess('Preview emails have been sent.'))
      .catch((e) => showError(e.message))
      .finally(() => setSendingPreviews(false));
  }

  function sendEmails() {
    form
      .validateFields()
      .then(() => {
        setSendingEmails(true);
        get(
          isReminder
            ? `/instances/${instanceId}/send_remainder_emails/0?reminderId=${currentEmailId}`
            : `/instances/${instanceId}/send_emails/0?emailId=${currentEmailId}`,
        )
          .then(() => {
            showSuccess(
              isReminder ? 'Reminder emails have been sent.' : 'Survey emails have been sent.',
            );
            onCancel();
          })
          .catch((e) => showError(e.message))
          .finally(() => setSendingEmails(false));
      })
      .catch((e) => {
        const errors = new Set();
        toArray(e.errorFields).forEach((item) => {
          item.errors.forEach((error) => {
            errors.add(error);
          });
        });
        if (errors.size) {
          setSurveySaving(false);
          showError(Array.from(errors).join('<br>'), 'Errors');
        } else if (e.message) {
          showError(e.message);
        }
      });
  }

  function onEditMode() {
    setEditMode(true);
    form.setFieldsValue({
      emails: surveyEmails.filter(
        (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
      ),
    });
  }

  function saveSurvey() {
    setSurveySaving(true);
    form
      .validateFields()
      .then((values) => {
        const otherEmails = surveyEmails.filter(
          (i) => i.typeId === (isReminder ? EMAIL_TYPES.INITIAL : EMAIL_TYPES.REMINDER),
        );
        put(
          `/surveys/${surveyId}/emails`,
          mapEmails([...otherEmails, ...values.emails], instance.survey?.locales),
        )
          .then((res) => {
            setSurveyEmails(mapEmailsFromApi(res, instance.survey?.locales));
            return post(`/instances/${instanceId}/preview_survey_emails`).then((rsp) => {
              const previewsList = mapEmailsFromApi(
                toArray(rsp).filter(
                  (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
                ),
                instance.survey?.locales,
              );
              setEmailPreviews(previewsList);
              setSurveySaving(false);
              setEditMode(false);
            });
          })
          .catch((e) => {
            showError(e.message);
            setSurveySaving(false);
          });
      })
      .catch((e) => {
        const errors = new Set();
        toArray(e.errorFields).forEach((item) => {
          item.errors.forEach((error) => {
            errors.add(error);
          });
        });
        if (errors.size) {
          setSurveySaving(false);
          showError(Array.from(errors).join('<br>'), 'Errors');
        }
      });
  }

  useEffect(() => {
    if (!visible) {
      setInstance({});
      setLoading(false);
      setCurrentPreviews({ en: {} });
      setLocales([]);
      setComponents({ en: {} });
      setLocale('en');
      setSendingPreviews(false);
      setSendingEmails(false);
      setSurveySaving(false);
      setEditMode(false);
      setSurveyEmails([]);
      setWarnings([]);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible || !toArray(surveyEmails).length || !toArray(instance.survey?.locales).length) {
      return;
    }
    setWarnings(
      localesEmailWarning(
        surveyEmails.filter(
          (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
        ),
        instance.survey.locales,
      ),
    );
  }, [visible, surveyEmails, instance]);

  useEffect(() => {
    if (!instanceId) {
      return;
    }
    setLoading(true);
    const promices = [
      get(`/instances/${instanceId}`),
      post(`/instances/${instanceId}/preview_survey_emails`),
    ];
    Promise.all(promices)
      .then((data) => {
        const instanceInfo = cloneDeep(data[0]);
        instanceInfo.survey = mapSurveyFromApi(data[0]?.survey);
        instanceInfo.survey.surveyGroups = data[0]?.survey?.surveyGroups;
        const surveyComponents = mapComponentsFromApi(
          data[0]?.survey?.components,
          instanceInfo.survey?.locales,
        );
        setComponents(surveyComponents);
        const emails = mapEmailsFromApi(
          toArray(data[0]?.survey?.emails),
          instanceInfo.survey?.locales,
        );
        setSurveyEmails(emails);
        form.setFieldsValue({
          emails: emails.filter(
            (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
          ),
        });
        setInstance(instanceInfo);
        const langs = (data[0].survey?.locales || 'en').split(',');
        setLocales(
          langs.map((item) => LOCALES.find((l) => item === l.locale)).filter((item) => item),
        );
        const previewsList = mapEmailsFromApi(
          toArray(data[1]).filter(
            (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
          ),
          instanceInfo.survey?.locales,
        );
        setEmailPreviews(previewsList);
        setCurrentEmailId(previewsList.length > 1 ? undefined : previewsList[0].emailId);
      })
      .catch((e) => showError(e.message))
      .finally(() => setLoading(false));
  }, [instanceId, isReminder]);

  useEffect(() => {
    if (currentEmailId) {
      const preview = emailPreviews.find((i) => i.emailId === currentEmailId);
      if (preview) {
        setCurrentPreviews(preview);
      }
    }
  }, [currentEmailId, emailPreviews]);

  const filteredEmails = surveyEmails.filter(
    (i) => i.typeId === (isReminder ? EMAIL_TYPES.REMINDER : EMAIL_TYPES.INITIAL),
  );

  const sendEmailMenu = {
    onClick: ({ key }) => {
      if (key === 'sendMe') {
        return sendPreviews();
      }
      if (key === 'sendOthers') {
        return setShowSendModal(true);
      }
    },
    items: [
      { label: 'Send me a preview', key: 'sendMe' },
      { label: 'Send to others', key: 'sendOthers' },
    ],
  };

  const runSurveyMenu = {
    onClick: ({ key }) => {
      if (key === 'runSurvey') {
        return sendEmails();
      }
      if (key === 'launchInf') {
        return setShowInformModal(true);
      }
    },
    items: [
      { label: 'Run survey', key: 'runSurvey' },
      { label: 'Launch & Inform', key: 'launchInf' },
    ],
  };

  return (
    <Modal
      title={getTitle()}
      open={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel} style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Dropdown disabled={isReminder && !currentEmailId} menu={sendEmailMenu}>
            <Button type="default" loading={sendingPreviews}>
              Send preview email
            </Button>
          </Dropdown>
          <Button onClick={() => setShowSeedModal(true)}>Seed survey</Button>
          {isReminder ? (
            <Button
              disabled={isReminder && !currentEmailId}
              onClick={sendEmails}
              type="primary"
              loading={sendingEmails}
            >
              Send reminder
            </Button>
          ) : isSuperAdmin ? (
            <Dropdown menu={runSurveyMenu}>
              <Button style={{ marginLeft: '8px' }} type="primary" loading={sendingEmails}>
                Run survey
              </Button>
            </Dropdown>
          ) : (
            <Button type="primary" onClick={() => sendEmails()} loading={sendingEmails}>
              Run survey
            </Button>
          )}
        </>
      }
      width={700}
    >
      {loading && <Loader />}
      <Row justify="space-between" className="no-wrap">
        <Col>
          <p>
            <b>Survey title:&nbsp;</b>
            {components[locale].intro || components.en.intro}
          </p>
        </Col>
        <Col>
          <Row gutter={[16, 0]} className="no-wrap">
            {filteredEmails.length > 1 && (
              <Col>
                <Select
                  placeholder="Select reminder"
                  onChange={setCurrentEmailId}
                  value={currentEmailId}
                  popupMatchSelectWidth={200}
                >
                  {filteredEmails.map((item) => (
                    <Option key={item.name} value={item.emailId}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
            {locales.length > 1 && (
              <Col>
                <Select onChange={setLocale} value={locale} popupMatchSelectWidth={200}>
                  {locales.map((item) => (
                    <Option key={item.locale} value={item.locale}>
                      {item.language}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <p>
        <b>Starts from&nbsp;</b>
        {moment(isReminder ? instance.startts : undefined).format(DEFAULT_UI_DATE_FORMAT)}
        <b>&nbsp;to&nbsp;</b>
        {moment(instance.endts).format(DEFAULT_UI_DATE_FORMAT)}
      </p>
      <p>
        <b>Participant groups:&nbsp;</b>
        {toArray(instance.survey?.surveyGroups)
          .map((item) => `${item.name} (${item.mem_num})`)
          .join(', ')}
      </p>
      {!!warnings.length && (
        <Alert
          message={<div dangerouslySetInnerHTML={{ __html: warnings.join('<br/>') }} />}
          type="warning"
        />
      )}
      <br />
      <Row justify="space-between" align="middle">
        <Col style={{ fontSize: '16px' }}>
          <b>Preview email for participants:</b>
        </Col>
        {!editMode && (
          <Col>
            <Button loading={loading} onClick={onEditMode} disabled={isReminder && !currentEmailId}>
              Edit
            </Button>
          </Col>
        )}
        {editMode && (
          <Col>
            <Button
              style={{ marginRight: '5px' }}
              loading={surveySaving}
              type="primary"
              onClick={saveSurvey}
            >
              Save
            </Button>
            <Button disabled={surveySaving} onClick={() => setEditMode(false)}>
              Cancel
            </Button>
          </Col>
        )}
      </Row>
      {!editMode && (!isReminder || currentEmailId) && (
        <>
          <p>
            <b>Subject:&nbsp;</b>
            {currentPreviews[locale]?.subject}
          </p>
          <p>
            <b>Body:</b>
          </p>
          <div dangerouslySetInnerHTML={{ __html: sanitize(currentPreviews[locale]?.body) }} />
        </>
      )}
      {!editMode && isReminder && !currentEmailId && (
        <div>Please select which reminder you want to sent</div>
      )}
      <Form
        layout="vertical"
        validateMessages={validateMessages}
        form={form}
        validateTrigger="onSubmit"
      >
        <Form.List name="emails">
          {(fields) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    name={[name, 'emailId']}
                    fieldKey={[fieldKey, 'emailId']}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'typeId']}
                    fieldKey={[fieldKey, 'typeId']}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    fieldKey={[fieldKey, 'name']}
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  {toArray(instance.survey?.locales).map((lang) => (
                    <div key={`${lang}-${key}-email`}>
                      <Form.Item name={[name, lang, 'id']} fieldKey={[fieldKey, lang, 'id']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name={[name, lang, 'subject']}
                        fieldKey={[fieldKey, lang, 'subject']}
                        hidden={
                          lang !== locale ||
                          filteredEmails[fieldKey]?.emailId !== currentEmailId ||
                          !editMode
                        }
                        rules={[{ required: lang === 'en' }]}
                      >
                        <Input placeholder="Enter email subject" />
                      </Form.Item>
                      <Form.Item
                        label="Body"
                        name={[name, lang, 'body']}
                        fieldKey={[fieldKey, lang, 'body']}
                        hidden={
                          lang !== locale ||
                          filteredEmails[fieldKey]?.emailId !== currentEmailId ||
                          !editMode
                        }
                        rules={[
                          (field) =>
                            localesEmailValidator(
                              field,
                              fieldKey,
                              toArray(instance.survey?.locales),
                              'emails',
                            ),
                        ]}
                        required={lang === 'en'}
                      >
                        <TextEditor
                          initialValue={toObject(filteredEmails[index])[lang]?.body}
                          specials={specialsForEmail}
                        />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form>
      <div />
      <SeedModal
        visible={showSeedModal}
        onCancel={() => setShowSeedModal(false)}
        surveyName={instance.survey?.surveyname}
        instanceId={instanceId}
        surveyId={surveyId}
        statementGroupDimensionId={instance.survey?.statementGroupDimensionId}
      />
      <SendPreview
        visible={showSendModal}
        onCancel={() => setShowSendModal(false)}
        onSave={sendPreviews}
      />
      <LaunchAndSend
        visible={showInformModal}
        onCancel={() => setShowInformModal(false)}
        onSave={onLaunchAndSend}
      />
    </Modal>
  );
});

Preview.defaultProps = {
  visible: false,
  onCancel: () => {},
  isReminder: false,
  surveyId: undefined,
  instanceId: undefined,
};

Preview.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  isReminder: PropTypes.bool,
  surveyId: PropTypes.number,
  instanceId: PropTypes.number,
};

export default Preview;
