import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import RootRedirectRoute from './components/RootRedirectRoute';
import RouteWithLayout from './components/RouteWithLayout';

const NotFound = lazy(() => import('./pages/404'));
const NotAllowed = lazy(() => import('./pages/403'));
const Maintenance = lazy(() => import('./pages/Maintenance'));
const Login = lazy(() => import('./pages/Login'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const DiscussionMaterials = lazy(() => import('./pages/DiscussionMaterials'));
const Questions = lazy(() => import('./pages/Questions'));
const Topics = lazy(() => import('./pages/Topics'));
const Categories = lazy(() => import('./pages/Categories'));
const Users = lazy(() => import('./pages/Users'));
const Dimensions = lazy(() => import('./pages/Dimensions'));
const Surveys = lazy(() => import('./pages/Surveys'));
const Survey = lazy(() => import('./pages/Survey'));
const Review = lazy(() => import('./pages/Review'));
const GettingStarted = lazy(() => import('./pages/GettingStarted'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const ServiceTerms = lazy(() => import('./pages/ServiceTerms'));
const GoCode = lazy(() => import('./pages/GoCode'));
const Settings = lazy(() => import('./pages/Settings'));
const Organizations = lazy(() => import('./pages/Organizations'));
const LoginSSO = lazy(() => import('./pages/LoginSSO'));
const LoginSSOCallback = lazy(() => import('./pages/LoginSSOCallback'));
const RegularPortfolioViews = lazy(() => import('./pages/RegularPortfolioViews'));
const Child360PortfolioViews = lazy(() => import('./pages/Child360PortfolioViews'));
const DashboardCS = lazy(() => import('./pages/DashboardCS'));
const Manage360PortfolioViews = lazy(() => import('./pages/Manage360PortfolioViews'));
const ManageRegularPortfolioViews = lazy(() => import('./pages/ManageRegularPortfolioViews'));
const PortfolioView = lazy(() => import('./pages/PortfolioView'));
const DEIReports = lazy(() => import('./pages/DEIReports'));
const VCPPlan = lazy(() => import('./pages/VCPPlan'));
const Edit360View = lazy(() => import('./pages/Edit360View'));
const DEIDashboard = lazy(() => import('./pages/DEIDashboard'));
const ManagerReviews = lazy(() => import('./pages/ManagerReviews'));
const ManagerReview = lazy(() => import('./pages/ManagerReview'));

const Routes = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute exact path="/">
            <RootRedirectRoute />
          </PrivateRoute>

          <RouteWithLayout
            exact
            path="/login"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Login"
          >
            <Login />
          </RouteWithLayout>
          <RouteWithLayout
            exact
            path="/change-password"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Change password"
          >
            <ChangePassword />
          </RouteWithLayout>
          <RouteWithLayout
            exact
            path="/login/sso"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Login"
          >
            <LoginSSO />
          </RouteWithLayout>
          <RouteWithLayout
            exact
            path="/login/sso/callback"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Login"
          >
            <LoginSSOCallback />
          </RouteWithLayout>
          <RouteWithLayout
            exact
            path="/forgot-password"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Forgot Password"
          >
            <ForgotPassword />
          </RouteWithLayout>
          <RouteWithLayout
            exact
            path="/reset_pass"
            pageClassName="no-auth-page"
            hideNavbar
            title="Entromy | Reset Password"
          >
            <ResetPassword />
          </RouteWithLayout>

          <PrivateRoute exact path="/dashboard" isAdmin>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/reports/discussion-materials">
            <DiscussionMaterials />
          </PrivateRoute>
          <PrivateRoute exact path="/reports/dei-dashboard" is>
            <DEIDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/reports/dei-reports" isAdmin>
            <DEIReports />
          </PrivateRoute>
          <PrivateRoute exact path="/internal/dashboard" isSuperAdmin>
            <DashboardCS />
          </PrivateRoute>
          <PrivateRoute exact path="/vcp/plan" isAdmin>
            <VCPPlan />
          </PrivateRoute>
          <PrivateRoute exact path="/manager-reviews" isDiscussionMaterialRole>
            <ManagerReviews />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/manager-review/:surveyGroupId/:surveyId/:reviewId"
            isDiscussionMaterialRole
          >
            <ManagerReview />
          </PrivateRoute>

          <Route path="/reports/portfolio">
            <Switch>
              <PrivateRoute exact path="/reports/portfolio" isAdmin>
                <RegularPortfolioViews />
              </PrivateRoute>
              <PrivateRoute exact path="/reports/portfolio/:portfolioId" isAdmin>
                <RegularPortfolioViews />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/reports/teamReport">
            <Switch>
              <PrivateRoute
                exact
                path="/reports/teamReport"
                allowedRoles={['isAdmin', 'isDiscussionMaterialRole']}
              >
                <Child360PortfolioViews />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/reports/teamReport/:portfolioId"
                allowedRoles={['isAdmin', 'isDiscussionMaterialRole']}
              >
                <Child360PortfolioViews />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/configure/questions">
            <Switch>
              <PrivateRoute exact path="/configure/questions" isAdmin>
                <Questions />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/questions/new" isAdmin>
                <Questions showQuestionModal />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/questions/edit/:id" isAdmin>
                <Questions showQuestionModal />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/configure/portfolioViews">
            <Switch>
              <PrivateRoute exact path="/configure/portfolioViews" isAdmin>
                <ManageRegularPortfolioViews />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/portfolioViews/new" isAdmin>
                <PortfolioView />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/portfolioViews/:portfolioId" isAdmin>
                <PortfolioView />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/reports/teamViews">
            <Switch>
              <PrivateRoute
                exact
                path="/reports/teamViews"
                allowedRoles={['isSuperAdmin', 'isDiscussionMaterialRole']}
              >
                <Manage360PortfolioViews />
              </PrivateRoute>
              <PrivateRoute exact path="/reports/teamViews/new" isSuperAdmin>
                <Edit360View />
              </PrivateRoute>
              <PrivateRoute exact path="/reports/teamViews/:portfolioId" isSuperAdmin>
                <Edit360View />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/configure/users">
            <Switch>
              <PrivateRoute exact path="/configure/users" isAdmin>
                <Users />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/users/new" isAdmin>
                <Users showUserModal />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/configure/topics">
            <Switch>
              <PrivateRoute exact path="/configure/topics" isAdmin>
                <Topics />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/topics/new" isAdmin>
                <Topics showTopicModal />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/topics/edit/:id" isAdmin>
                <Topics showTopicModal />
              </PrivateRoute>
            </Switch>
          </Route>

          <Route path="/configure/surveys">
            <Switch>
              <PrivateRoute exact path="/configure/surveys" isAdmin>
                <Surveys />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/surveys/new" isAdmin>
                <Survey />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/surveys/edit/:surveyId" isAdmin>
                <Survey />
              </PrivateRoute>
              <PrivateRoute exact path="/configure/surveys/new/:templateId" isAdmin>
                <Survey />
              </PrivateRoute>
            </Switch>
          </Route>

          <PrivateRoute path="/configure/dimensions/:subPage?" isAdmin>
            <Dimensions />
          </PrivateRoute>

          <PrivateRoute exact path="/configure/categories" isAdmin>
            <Categories />
          </PrivateRoute>

          <PrivateRoute exact path="/review" isAdmin>
            <Review />
          </PrivateRoute>

          <PrivateRoute exact path="/getting-started">
            <GettingStarted />
          </PrivateRoute>

          <RouteWithLayout exact path="/privacy-policy">
            <PrivacyPolicy />
          </RouteWithLayout>
          <RouteWithLayout exact path="/terms-of-service">
            <ServiceTerms />
          </RouteWithLayout>

          <Route exact path="/register/:code">
            <GoCode paramName="SurveyCode" />
          </Route>

          <Route exact path="/go/:code">
            <GoCode paramName="goCode" />
          </Route>

          <PrivateRoute exact path="/settings" isAdmin>
            <Settings />
          </PrivateRoute>

          <PrivateRoute exact path="/organizations" isSuperAdmin>
            <Organizations />
          </PrivateRoute>

          <Route exact path="/not-allowed">
            <NotAllowed />
          </Route>
          <Route exact path="/maintenance">
            <Maintenance />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
